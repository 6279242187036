import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  CUSTOMER_TYPE,
  POLICY_TYPE,
  documentType,
  insurers,
  kycDoc,
  kycDocName,
  kycType,
  toastFailure
} from '../../utility/constants'
import { useForm } from 'react-hook-form'
import {
  dispatchMotorQuote,
  dispatchProcessFlowMotor
} from '../../../store/actions/userActions'
import { addDocuments, getKyc, processFlow } from '../../common/Homepage/TPApiCall'
import { toast } from 'react-toastify'
import { getVehiclePincode } from '../../Services/masterServices'
import ReactSelect from '../../common/Tags/ReactSelectProposal'
import { load } from 'react-cookies'
import RedirectionModal from '../../common/ProposalPage/RedirectionModal'
import { useNavigate } from 'react-router-dom'
import jsPDF from 'jspdf'
import { IoMdGitPullRequest } from 'react-icons/io'

const KYC = ({ setActiveTab, kycStatusCheck, handleStepClick }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue,
    getValues,
    watch,
    clearErrors
  } = useForm({ mode: 'onBlur' })

  const {
    handleSubmit: handleSubmit1,
    register: register1,
    formState: { errors: errors1 },
    reset: reset1,
    setValue: setValue1,
    clearErrors: clearErrors1
  } = useForm({ mode: 'onBlur' })

  const motorRequest = useSelector(state => state?.root?.motorRequest)
  const processFlowObjMotor = useSelector(
    state => state?.root?.processFlowObjMotor
  )
  const kycConfig = useSelector(state => state?.root?.kycConfig)
  const selectedPlan = useSelector(state => state?.root?.selectedPlan)
  const [proceedSpinner, setProceedSpinner] = useState(false)
  const [proceedBackSpinner, setProceedBackSpinner] = useState(false)
  const [kycMode, setKycMode] = useState('ckyc')
  const [kycStatus, setKycStatus] = useState(false)
  const [pincodeData, setPincodeData] = useState([])
  const [redirectionModal, setRedirectionModal] = useState(false)
  const [redirectionUrl, setRedirectionUrl] = useState(null)
  const [redirection, setRedirection] = useState(false);
  const [pincodeError, setPincodeError] = useState(false)
  const [kycFields, setKycFields] = useState('')
  const [address, setAddress] = useState({
    addressLine1: motorRequest?.addressLine1 || '',
    addressLine2: motorRequest?.addressLine2 || '',
    addressLine3: motorRequest?.addressLine3 || '',
    pincode: motorRequest?.pincode || ''
  })
  const [addressErrors, setAddressErrors] = useState({})
  const QuickQouteResult = useSelector(state => state?.root?.QuickQouteResult)
  const [images, setImages] = useState(null);
  const [imagesLink, setImagesLink] = useState(null);
  const [imageLinkError, setImageLinkError] = useState(false);
  const [docImages, setDocImages] = useState({
    frontId: null,
    backId: null,
  });
  const navigate = useNavigate()

  useEffect(() => {
    if ( kycStatusCheck && kycConfig) {
            setKycFields(
        kycConfig?.KycFieldConfig.filter(
          kycType => kycType.kycType === motorRequest?.kycType?.toLowerCase()
        )
      )
      dispatchMotorQuote(
        'kycFields',
        kycConfig?.KycFieldConfig.filter(
          kycType => kycType.kycType === motorRequest?.kycType?.toLowerCase()
        )
      )
      console.log(
        'kycFields',
        kycConfig?.KycFieldConfig.filter(
          kycType => kycType.kycType === motorRequest?.kycType?.toLowerCase()
        )
      )
    }
    setValue('docName', motorRequest?.docName)
  }, [kycConfig, kycStatusCheck,motorRequest?.kycType])

  const getKycStatus = async () => {
    setProceedSpinner(true);
    const postData = {
      caseId: motorRequest?.caseId,
      vehicleType: motorRequest?.vehicleType,
      insurerId: selectedPlan?.insurerId,
    };
    const response = await getKycServiceStatus(postData);
    if (response?.status === "success" && response?.data?.kycStatus) {
      const newDataForFlow = {
        ...processFlowObjMotor?.step8?.kycAndUserDetails,
      };
      newDataForFlow.firstName = motorRequest?.firstName;
      const newData = {
        ...newDataForFlow,
        kycStatus: "completed",
        kycType: motorRequest?.kycType,
      };
      dispatchProcessFlowMotor("step8.kycAndUserDetails", newData);
      dispatchMotorQuote("kycStatus", "completed");
      dispatchMotorQuote("kycData", response?.data?.kycData);
      setKycStatus(true);
      toastSuccess("KYC Successful");
      const dataForFlow = {
        ...processFlowObjMotor,
        customerLeadId: motorRequest?.customerLeadId,
        motorRequest: { ...motorRequest },
        processDiscription: "kycAndUserDetails",
        step: "step8",
        step8: {
          ...processFlowObjMotor.step8,
          motorRequest: { ...motorRequest },
          kycAndUserDetails: newData,
          kycConfig: kycConfig,
          planDetails: selectedPlan,
        },
      };
      processFlow(dataForFlow);
      setProceedSpinner(false);
    } else {
      setProceedSpinner(false);
      toastFailure(
        `${
          response?.data?.errors
            ? response?.data?.errors[0]?.displayMessage
            : "KYC Failed. Please check your details and try again"
        }`
      );
    }
  };

  const handleAddressChange = e => {
    const { name, value } = e.target
    if (name === 'addressLine1') {
      setAddressErrors(prev => ({
        ...prev,
        addressLine1: ''
      }))
    } else if (name === 'addressLine2') {
      setAddressErrors(prev => ({
        ...prev,
        addressLine2: ''
      }))
    } else if (name === 'addressLine3') {
      setAddressErrors(prev => ({
        ...prev,
        addressLine3: ''
      }))
    }
    setAddress({ ...address, [name]: value })
    dispatchMotorQuote(name, value)
  }

  const validateAddressFields = () => {
    let isValid = true
    const newErrors = {}

    if (!address.addressLine1) {
      newErrors.addressLine1 = 'Address 1 is required'
      isValid = false
    }
    if (!address.addressLine2) {
      newErrors.addressLine2 = 'Address 2 is required'
      isValid = false
    }
    if (!address.addressLine3) {
      newErrors.addressLine3 = 'Address 3 is required'
      isValid = false
    }
   
    setAddressErrors(newErrors)
    return isValid
  }

  const handleNextClick = () => {
    if (motorRequest?.pincode === '') {
      setPincodeError(true)
      return
    } else {
      setPincodeError(false)
    }
    if (validateAddressFields()) {
      if (selectedPlan?.insurerId === 10 || selectedPlan?.PersonalAccident) {
        setActiveTab(2)
        handleStepClick(2)
      } else {
        setActiveTab(3)
        handleStepClick(3)
      }
      const dataForFlow = {
        ...processFlowObjMotor,
        customerLeadId: motorRequest?.customerLeadId,
        motorRequest: { ...motorRequest },
        processDiscription: 'kycAndUserDetails',
        step: 'step8',
        step8: {
          ...processFlowObjMotor.step8,
          kycAndUserDetails: {
            ...processFlowObjMotor.step8.kycAndUserDetails,
            maritalStatus: motorRequest?.maritalStatus,
            addressLine1: motorRequest?.addressLine1,
            addressLine2: motorRequest?.addressLine2,
            addressLine3: motorRequest?.addressLine3,
            cityId: motorRequest?.cityId,
            city: motorRequest?.city,
            stateId: motorRequest?.stateId,
            state: motorRequest?.state,
            pincode: motorRequest?.pincode
          },
          motorRequest: { ...motorRequest },
          planDetails: selectedPlan
        }
      }
      processFlow(dataForFlow)
    }
  }

  useEffect(() => {
    getVehiclePincode()
      .then(response => {
        if (response.status === true) {
          let i = 0
          let arr = []
          let arr1 = []
          let j = 0
          while (i < response.data.length) {
            arr.push({
              value: response.data[i].Pin_Code,
              label:
                response.data[i].Pin_Code + ' - ' + response.data[i].District,
              District: response.data[i].District,
              City: response.data[i].City,
              State: response.data[i].State,
              cityId: response.data[i].cityId,
              stateId: response.data[i].stateId
            })

            i++
          }
          setPincodeData(arr)
        }
      })
      .catch(err => console.log(err))
  }, [])

  // const submitKycDetails = async (data) => {
  //   if (motorRequest?.kycType === "ovd") {
  //     if (!imagesLink) {
  //       setImageLinkError(true);
  //       return;
  //     }
  //   }
  //   setProceedSpinner(true)
  //   let newData = { ...motorRequest }

  //   if (
  //     kycConfig?.kycConfig?.kycStep === 'in_proposal' ||
  //     kycConfig?.kycConfig?.kycStep === 'after_proposal'
  //   ) {
  //     for (let key in data) {
  //       if (key === 'documentNo') {
  //         dispatchMotorQuote(`docNumber`, data[key])
  //         newData[key] = data[key]
  //       } else {
  //         dispatchMotorQuote(`${key}`, data[key])
  //         newData[key] = data[key]
  //       }
  //     }
  //     const dataForFlow = {
  //       ...processFlowObjMotor,
  //       customerLeadId: motorRequest?.customerLeadId,
  //       motorRequest: { ...motorRequest },
  //       processDiscription: 'kycAndUserDetails',
  //       step: 'step8',
  //       step8: {
  //         ...processFlowObjMotor.step8,
  //         motorRequest: { ...motorRequest },
  //         kycAndUserDetails: newData,
  //         kycConfig: kycConfig,
  //         planDetails: selectedPlan
  //       }
  //     }
  //     processFlow(dataForFlow)
  //     if (selectedPlan?.insurerId === 10 || selectedPlan?.PersonalAccident) {
  //       setActiveTab(2)
  //     } else {
  //       setActiveTab(3)
  //     }
  //   }

  //   if (!kycStatus && kycConfig?.kycConfig?.kycStep === 'before_proposal') {
  //     for (let key in data) {
  //       dispatchMotorQuote(`${key}`, data[key])
  //       newData[key] = data[key]
  //     }

  //     try {
  //       const response = await getKyc(newData, selectedPlan)
  //       if (response?.data?.kycStatus === 'success') {
  //         let newDataForFlow = { ...data }
  //         newDataForFlow.firstName = motorRequest?.firstName;
  //         const newData = {
  //           ...newDataForFlow,
  //           ...data,
  //           kycStatus: 'completed',
  //           kycType: motorRequest?.kycType,
  //         }
  //         dispatchProcessFlowMotor('step8.kycAndUserDetails', newData)
  //         dispatchMotorQuote('kycStatus', 'completed')
  //         dispatchMotorQuote('kycData', response?.data?.kycData)
  //         toast.success('KYC Successful', {
  //           position: 'top-right',
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: 'light'
  //         })
  //         const dataForFlow = {
  //           ...processFlowObjMotor,
  //           customerLeadId: motorRequest?.customerLeadId,
  //           motorRequest: { ...motorRequest },
  //           processDiscription: 'kycAndUserDetails',
  //           step: 'step8',
  //           step8: {
  //             ...processFlowObjMotor.step8,
  //             motorRequest: { ...motorRequest },
  //             kycAndUserDetails: newData,
  //             kycConfig: kycConfig,
  //             planDetails: selectedPlan
  //           }
  //         }
  //         processFlow(dataForFlow)
  //         setKycStatus(true)
  //         setProceedSpinner(false)
  //       } 
  //       else if (response?.data?.kycStatus === 'failure') {          
  //         dispatchMotorQuote('kycStatus', 'failure')
  //         dispatchProcessFlowMotor("step", "step8");
  //         if (kycConfig?.kycConfig?.isOvdAllowed) {
  //           dispatchMotorQuote("ovdActive", true);
  //         }
  //         let newDataForFlow = { ...data };
  //         newDataForFlow.firstName = motorRequest?.firstName;
  //         console.log(" data.firstName", newDataForFlow);
  //         const newData = {
  //           ...newDataForFlow,
  //           kycStatus: "failure",
  //           kycType: motorRequest?.kycType,
  //         };
  //         console.log("newData", newData);
  //         const dataForFlow = {
  //           ...processFlowObjMotor,
  //           customerLeadId: motorRequest?.customerLeadId,
  //           motorRequest: { ...motorRequest },
  //           processDiscription: "kycAndUserDetails",
  //           step: "step8",
  //           step8: {
  //             ...processFlowObjMotor.step8,
  //             motorRequest: { ...motorRequest },
  //             kycAndUserDetails: newData,
  //             kycConfig: kycConfig,
  //             planDetails: selectedPlan,
  //           },
  //         };
  //         processFlow(dataForFlow);
  //         // const data = { ...processFlowObjMotor, kycStatus: "failure" };
  //         // dispatchProcessFlowMotor("step8.kycAndUserDetails", data);
  //         dispatchMotorQuote("kycStatus", "failure");
  //         setRedirectionUrl(response?.data?.redirectionUrl);
  //         dispatchMotorQuote('redirection', true)
  //         console.log("responce-------",response?.data?.redirectionUrl); 
  //         toast.error( `Your KYC has been failed. We are redirecting you to Insurer's Portal`)
  //         if (selectedPlan?.insurerId === insurers.HDFC.insurerId) {
  //           setTimeout(() => {
  //             response?.data?.redirectionUrl &&
  //               window.open(response?.data?.redirectionUrl, "_blank");
  //           }, [2000]);
  //           setTimeout(() => {
  //             setRedirection(true);
  //           }, [10000]);
  //           setProceedSpinner(false);

  //           return;
  //         } else if (selectedPlan?.insurerId === insurers.Kotak.insurerId) {
  //           setTimeout(() => {
  //             response?.data?.redirectionUrl &&
  //               window.open(response?.data?.redirectionUrl);
  //             setProceedSpinner(false);
  //           }, [2000]);


  //           return;
         

  //         } else if (selectedPlan?.insurerId === insurers.Future.insurerId){
  //           setTimeout( () => {
  //             response?.data?.redirectionUrl && 
  //             window.open(response?.data?.redirectionUrl,"_blank")

  //           },[2000] )
  //           setTimeout(() => {
  //             setRedirection(true)
  //           },[10000])
  //           setRedirection(false);
  //           return

  //         } 
  //         else if (selectedPlan?.insurerId === insurers.Zuno.insurerId){

  //           setTimeout( () => {
  //             response?.data?.redirectionUrl && 
  //             window.open(response?.data?.redirectionUrl,"_blank")

  //           },[2000] )
  //           setTimeout(() => {
  //             setRedirection(true)
  //           },[10000])
  //           setRedirection(false);
  //           return

  //         } 
  //         else if (selectedPlan?.insurerId === insurers.united.insurerId){

  //           setTimeout( () => {
  //             response?.data?.redirectionUrl && 
  //             window.open(response?.data?.redirectionUrl,"_blank")

  //           },[2000] )
  //           setTimeout(() => {
  //             setRedirection(true)
  //           },[10000])
  //           setRedirection(false);
  //           return

  //         }
  //         toastFailure(
  //           `Your KYC has been failed. We are redirecting you to Insurer's Portal`
  //         );
         
  //         toast.error(
  //           `${
  //             response?.data?.errors
  //               ? response?.data?.errors[0]?.displayMessage
  //               : 'KYC Failed. Please check your details and try again'
  //           }`,
  //           {
  //             position: 'top-right',
  //             autoClose: 5000,
  //             hideProgressBar: false,
  //             closeOnClick: true,
  //             pauseOnHover: true,
  //             draggable: true,
  //             progress: undefined,
  //             theme: 'light'
  //           }
  //         )
  //         setProceedSpinner(false)
  //       } else {
  //         setProceedSpinner(false)
  //         toast.error(
  //           `${
  //             response?.data?.errors
  //               ? response?.data?.errors[0]?.displayMessage
  //               : 'KYC Failed. Please check your details and try again'
  //           }`,
  //           {
  //             position: 'top-right',
  //             autoClose: 5000,
  //             hideProgressBar: false,
  //             closeOnClick: true,
  //             pauseOnHover: true,
  //             draggable: true,
  //             progress: undefined,
  //             theme: 'light'
  //           }
  //         )
  //       }
        

  //     } catch (error) {
  //       toast.error(
  //         `${'KYC Failed. Please check your details and try again'}`,
  //         {
  //           position: 'top-right',
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: 'light'
  //         }
  //       )
  //       setProceedSpinner(false)
  //     }
  //   }

  //   if (kycStatus) {
  //     setActiveTab(2)
  //   }
  // }
  const submitKycDetails = async (data) => {

    if (motorRequest?.kycType === "ovd") {
      // if (!imagesLink) {
      //   setImageLinkError(true);
      //   return;
      // }
      const reqField = kycFields.find(
        (doc) => doc?.docName === watch("docName")
      ).requiredField;

      if (reqField.includes("uploadFrontPage")) {
        if (!docImages?.frontId && !motorRequest?.frontId) {
          setImageLinkError(true);
          moveToTop();
          return;
        }
      }
      if (reqField.includes("uploadBackPage")) {
        if (!docImages?.backId && !motorRequest?.backId) {
          setImageLinkError(true);
          moveToTop();
          return;
        }
      }
      if (reqField.includes("insured_photo")) {
        if (!docImages?.insuredPhotoId && !motorRequest?.insuredPhotoId) {
          setImageLinkError(true);
          moveToTop();
          return;
        }
      }
      if (reqField.includes("form60Link")) {
        if (!docImages?.form60Id && !motorRequest?.form60Id) {
          setImageLinkError(true);
          moveToTop();
          return;
        }
      }
    }
    setProceedSpinner(true);
    let newData = { ...motorRequest };
    if (
      selectedPlan?.Api_name === insurers.Digit.name ||
      selectedPlan?.insurerId === insurers.Digit.insurerId 
    ) {
      let nameForDigit = {
        firstName: "",
        middleName: "",
        lastName: "",
      };

      for (let key in data) {
        if (key === "documentNo") {
          dispatchMotorQuote(`docNumber`, data[key]);
          newData[key] = data[key];
        } else {
          dispatchMotorQuote(`${key}`, data[key]);
          newData[key] = data[key];
        }
        // if (data?.hasOwnProperty("lastName")) {
        //   // if (key === "firstName") {
        //   //   console.log("data[key]", data[key]);
        //   //   const nameParts = data[key]?.split(" ");
        //   //   if (nameParts.length > 0) {
        //   //     nameForDigit.firstName = nameParts[0];
        //   //   }
        //   //   if (nameParts.length > 1) {
        //   //     console.log(
        //   //       "nameForKyc.middleName",
        //   //       nameParts,
        //   //       nameParts[nameParts.length - 1]
        //   //     );
        //   //     nameForDigit.middleName = nameParts[nameParts.length - 1];
        //   //   }
        //   //   if (nameParts.length > 2) {
        //   //     nameForDigit.middleName = nameParts[1];
        //   //   }
        //   // } else if (key === "lastName") {
        //   //   nameForDigit.lastName = data[key];
        //   // } else
        //   if (key === "documentNo") {
        //     dispatchMotorQuote(`docNumber`, data[key]);
        //     newData[key] = data[key];
        //   } else {
        //     dispatchMotorQuote(`${key}`, data[key]);
        //     newData[key] = data[key];
        //   }
        // } else {
        //   // if (key === "firstName") {
        //   //   console.log("data[key]", data[key]);
        //   //   const nameParts = data[key]?.split(" ");
        //   //   if (nameParts.length > 0) {
        //   //     nameForDigit.firstName = nameParts[0];
        //   //   }
        //   //   if (nameParts.length > 1) {
        //   //     nameForDigit.lastName = nameParts[nameParts.length - 1];
        //   //   }
        //   //   if (nameParts.length > 2) {
        //   //     nameForDigit.middleName = nameParts[1];
        //   //   }
        //   // } else
        //   if (key === "documentNo") {
        //     dispatchMotorQuote(`docNumber`, data[key]);
        //     newData[key] = data[key];
        //   } else {
        //     dispatchMotorQuote(`${key}`, data[key]);
        //     newData[key] = data[key];
        //   }
        // }
      }
      // for (let key in nameForDigit) {
      //   const capitalizedValue = capitalizeValue(nameForDigit[key]);
      //   console.log(key, capitalizedValue);
      //   dispatchMotorQuote(key, capitalizedValue);
      // }
      const dataForFlow = {
        ...processFlowObjMotor,
        customerLeadId: motorRequest?.customerLeadId,
        motorRequest: { ...motorRequest },
        processDiscription: "kycAndUserDetails",
        step: "step8",
        step8: {
          ...processFlowObjMotor.step8,
          motorRequest: { ...motorRequest },
          kycAndUserDetails: newData,
          kycConfig: kycConfig,
          planDetails: selectedPlan,
        },
      };
      processFlow(dataForFlow);
      if (selectedPlan?.PersonalAccident) {
        setActiveTab(2)
        return;
      } else {
        setActiveTab(3)
        return;
      }
    }

    let nameForKyc = {
      firstName: "",
      middleName: "",
      lastName: "",
    };

    if (!kycStatus) {
      for (let key in data) {
        dispatchProcessFlowMotor("step", "step8");
        // if (key === "firstName") continue;
        // if (data?.hasOwnProperty("lastName")) {
        //   if (key === "firstName") {
        //     console.log("data[key]", data[key]);
        //     const nameParts = data[key]?.split(" ");
        //     if (nameParts.length > 0) {
        //       nameForKyc.firstName = nameParts[0];
        //     }
        //     if (nameParts.length > 1) {
        //       console.log(
        //         "nameForKyc.middleName",
        //         nameParts,
        //         nameParts[nameParts.length - 1]
        //       );
        //       nameForKyc.middleName = nameParts[nameParts.length - 1];
        //     }
        //     if (nameParts.length > 2) {
        //       nameForKyc.middleName = nameParts[1];
        //     }
        //   } else if (key === "lastName") {
        //     nameForKyc.lastName = data[key];
        //   }
        // } else {
        //   if (key === "firstName") {
        //     console.log("data[key]", data[key]);
        //     const nameParts = data[key]?.split(" ");
        //     if (nameParts.length > 0) {
        //       nameForKyc.firstName = nameParts[0];
        //     }
        //     if (nameParts.length > 1) {
        //       nameForKyc.lastName = nameParts[nameParts.length - 1];
        //     }
        //     if (nameParts.length > 2) {
        //       nameForKyc.middleName = nameParts[1];
        //     }
        //   }
        // }
        // console.log("nameForKyc", nameForKyc);
        dispatchMotorQuote(`${key}`, data[key]);
        // for (let key in nameForKyc) {
        //   dispatchMotorQuote(`${key}`, nameForKyc[key]);
        // }

        // for (let key in nameForKyc) {
        //   const capitalizedValue = capitalizeValue(nameForKyc[key]);
        //   console.log(key, capitalizedValue);
        //   dispatchMotorQuote(key, capitalizedValue);
        // }

        dispatchProcessFlowMotor(`step8.kycAndUserDetails.${key}`, data[key]);
        newData[key] = data[key];
      }

      try {

        const response = await getKyc(newData, selectedPlan, kycFields);

        if (response?.data?.kycStatus === "success") {
          dispatchProcessFlowMotor("step", "step8");
          let newDataForFlow = { ...data };
          newDataForFlow.firstName = motorRequest?.firstName;
          const newData = {
            ...newDataForFlow,
            kycStatus: "completed",
            kycType: motorRequest?.kycType,
          };
          dispatchProcessFlowMotor("step8.kycAndUserDetails", newData);

          dispatchMotorQuote("kycStatus", "completed");
          dispatchMotorQuote("kycData", response?.data?.kycData);
          toast.success("KYC Successful", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setKycStatus(true);
          const dataForFlow = {
            ...processFlowObjMotor,
            customerLeadId: motorRequest?.customerLeadId,
            motorRequest: { ...motorRequest },
            processDiscription: "kycAndUserDetails",
            step: "step8",
            step8: {
              ...processFlowObjMotor.step8,
              motorRequest: { ...motorRequest },
              kycAndUserDetails: newData,
              kycConfig: kycConfig,
              planDetails: selectedPlan,
            },
          };
          processFlow(dataForFlow);
          setProceedSpinner(false);
        } else if (response?.data?.kycStatus === "failure") {
          dispatchProcessFlowMotor("step", "step8");
          if (kycConfig?.kycConfig?.isOvdAllowed) {
            dispatchMotorQuote("ovdActive", true);
          }
          let newDataForFlow = { ...data };
          newDataForFlow.firstName = motorRequest?.firstName;
          const newData = {
            ...newDataForFlow,
            kycStatus: "failure",
            kycType: motorRequest?.kycType,
          };
          const dataForFlow = {
            ...processFlowObjMotor,
            customerLeadId: motorRequest?.customerLeadId,
            motorRequest: { ...motorRequest },
            processDiscription: "kycAndUserDetails",
            step: "step8",
            step8: {
              ...processFlowObjMotor.step8,
              motorRequest: { ...motorRequest },
              kycAndUserDetails: newData,
              kycConfig: kycConfig,
              planDetails: selectedPlan,
            },
          };
          processFlow(dataForFlow);
          // const data = { ...processFlowObjMotor, kycStatus: "failure" };
          // dispatchProcessFlowMotor("step8.kycAndUserDetails", data);
          dispatchMotorQuote("kycStatus", "failure");

          setRedirectionUrl(response?.data?.redirectionUrl);
          dispatchMotorQuote('redirection', true)

          // toastFailure(
          //   `Your KYC has been failed. We are redirecting you to Insurer's Portal`
          // );
          if (selectedPlan?.insurerId === insurers.Zuno.insurerId) {
            setTimeout(() => {
              response?.data?.redirectionUrl &&
                window.open(response?.data?.redirectionUrl, "_blank");
            }, [2000]);
            setTimeout(() => {
              setRedirection(true);
            }, [10000]);
            setProceedSpinner(false);

            return;
          } else if (selectedPlan?.insurerId === insurers.Kotak.insurerId) {
            setTimeout(() => {
              response?.data?.redirectionUrl &&
                window.open(response?.data?.redirectionUrl);
              setProceedSpinner(false);
            }, [2000]);

            return;
          }
           else if (selectedPlan?.insurerId === insurers.Future.insurerId) {
            setTimeout(() => {
              response?.data?.redirectionUrl &&
                window.open(response?.data?.redirectionUrl);
              setProceedSpinner(false);
            }, [2000]);

            return;
          }
           else if (selectedPlan?.insurerId === insurers.united.insurerId) {
            setTimeout(() => {
              response?.data?.redirectionUrl &&
                window.open(response?.data?.redirectionUrl);
              setProceedSpinner(false);
            }, [2000]);

            return;
          }
           else if (selectedPlan?.insurerId === insurers.HDFC.insurerId) {
            setTimeout(() => {
              response?.data?.redirectionUrl &&
                window.open(response?.data?.redirectionUrl);
              setProceedSpinner(false);
            }, [2000]);

            return;
          }
          toastFailure(
            `${
              response?.data?.errors
                ? response?.data?.errors[0]?.displayMessage
                : "KYC Failed. Please check your details and try again"
            }`
          );
          setProceedSpinner(false);
        } else {
          setProceedSpinner(false);
          toast.error(
            `${
              response?.data?.errors
                ? response?.data?.errors[0]?.displayMessage
                : "KYC Failed. Please check your details and try again"
            }`,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        }
      } catch (error) {
        toast.error(
          `${"KYC Failed. Please check your details and try again"}`,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
        console.error("Error occurred:", error);
        setProceedSpinner(false);
      }
    }

    if (kycStatus) {
      setActiveTab(2)
    }
  };


  const handleSelectPincode = data => {
    setPincodeError(false)
    dispatchMotorQuote('cityId', data?.cityId)
    dispatchMotorQuote('stateId', data?.stateId)
    dispatchMotorQuote('pincode', data?.value)
    dispatchMotorQuote('city', data?.City)
    dispatchMotorQuote('state', data?.State)
    reset({
      city: data?.City,
      state: data?.State,
      docName: getValues('docName')
    })

    // setValue("pincode", data?.Pin_Code);
  }

  const handleUploadFile = e => {
    console.log('e.target.files', e.target)
  }

  const handleInputChange = e => {
    const value = e?.target?.value.toUpperCase()
    setValue(e?.target?.name, value)
  }

  const handleMaritalStatus = e => {
    const value = e.target.value
    dispatchMotorQuote('maritalStatus', value)
  }

  const handlePreviousPage = () => {    
    setProceedBackSpinner(true)
    const newData = {
      customerLeadId: motorRequest?.customerLeadId,
      step: 'step7',
      motorRequest: { ...motorRequest },
      processDiscription: 'quotation',
      step7: {
        ...processFlowObjMotor.step7,
        motorRequest: { ...motorRequest },
        QuickQouteResult: { ...QuickQouteResult }
      }
    }
    processFlow(newData)
    setTimeout(() => {
      navigate(`/quotelist/${motorRequest?.customerLeadId}`)
      setProceedBackSpinner(false)
    }, [1000])
  }

  const handleKycDocUpload = async (pdfBlob) => {
    const hasBothImages = docImages.frontId && docImages.backId;
    if (hasBothImages) {
      const postData = {
        caseId: selectedPlan?.caseId,
        documentType: documentType.kyc,
        file: pdfBlob,
      };
      try {
        const response = await addDocuments(postData);
        if (response?.status === "success") {
          setImagesLink(response?.data);
          setValue("docLink", response?.data);
          dispatchMotorQuote("kycImageLink", response?.data);
        } else {
          toastFailure(
            response?.data?.errors[0]?.displayMessage ||
              "Error in uploading image"
          );
        }
      } catch (error) {
        console.log("Error in uploading doc", error);
      }
    }
  };

  const handleFileUpload = (e) => {
    if (e.target.files) {
      if (
        e.target.files[0].type.includes("image") ||
        e.target.files[0].type.includes("pdf")
      ) {
        setImages(e.target.files[0]);
        setImageLinkError(false);
      } else {
        alert("Error in uploading image");
      }
    } else {
      console.log("No file selected");
    }
  };

  useEffect(() => {
    if (images) {
      handleKycDocUpload();
    }
  }, [images]);

   useEffect(() => {
    if (getValues("city") === undefined && getValues("state") === undefined) {
      if (
        motorRequest?.cityId &&
        motorRequest?.city &&
        motorRequest?.stateId &&
        motorRequest?.state
      ) {
        setValue("city", motorRequest?.city);
        setValue("state", motorRequest?.state);
      }
    }
  }, [errors]);


  const handlePictureUpload = (e) => {
    const file = e.target.files[0];
    const fieldName = e.target.name;


    if (file && file.type.includes("image")) {
      setDocImages((prev) => ({
        ...prev,
        [fieldName]: file,
      }));
    }
  };
  const generatePDF = async () => {
    const pdf = new jsPDF();

    if (docImages.frontId) {
      const frontImageData = await getBase64(docImages.frontId);
      pdf.addImage(frontImageData, "JPEG", 10, 10, 180, 160);
    }

    if (docImages.backId) {
      const backImageData = await getBase64(docImages.backId);
      pdf.addPage();
      pdf.addImage(backImageData, "JPEG", 10, 10, 180, 160);
    }

    const pdfBlob = pdf.output("blob");
    handleKycDocUpload(pdfBlob);
    // pdf.save("document.pdf"); // Save the generated PDF
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  useEffect(() => {
    const hasBothImages = docImages.frontId && docImages.backId;
    if (hasBothImages) {
      //   createPdf(docImages);
      generatePDF();
    }
  }, [docImages.frontId, docImages.backId]);

  const handleDocNameChange = (e) => {
    const selectedDocName = e.target.value;
    setValue("docName", selectedDocName); // Manually trigger form state update
    if (motorRequest?.docName === selectedDocName) {
      setValue("docNumber", motorRequest?.docNumber);
    } else if (motorRequest?.docName !== selectedDocName) {
      setValue("docNumber", "");
    }
  };

  return (
    <form onSubmit={handleSubmit(submitKycDetails)} className='w-100'>
      <div className='posformbox  mx-auto'>
        <div className='row'>
        {kycConfig?.kycConfig?.isOvdAllowed && motorRequest?.ovdActive && (
        <div
          className="nav nav-pills mb-3 mt-3 tab_owner_tab"
          id="pills-tab"
          role="tablist"
        >
          <li class="nav-item" role="presentation">
            <button
              className={`nav-link ${
                motorRequest?.kycType === "ckyc" ? "active" : ""
              }`}
              id="pills-home-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-home"
              type="button"
              role="tab"
              aria-controls="pills-home"
              aria-selected={`${
                motorRequest?.kycType === "ckyc" ? true : false
              }`}
              onClick={() => {
                setKycMode("ckyc");
                dispatchMotorQuote("kycType", "ckyc");
              }}
            >
              CKYC
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              className={`nav-link ${
                motorRequest?.kycType === "ovd" ? "active" : ""
              }`}
              id="pills-profile-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-profile"
              type="button"
              role="tab"
              aria-controls="pills-profile"
              aria-selected={`${
                motorRequest?.kycType === "ovd" ? true : false
              }`}
              onClick={() => {
                setKycMode("ovd");
                dispatchMotorQuote("kycType", "ovd");
              }}
              // onClick={handleOVDMode}
            >
              OVD
            </button>
          </li>
        </div>
      )}
          <div className='col-md-6 mb-2'>
           
            <div className='onwer-details'>
              <div
                className='did-floating-label-content'
                style={{ marginBottom: '0px' }}
              >
                <>
                  <select
                    className='did-floating-input'
                    {...register('docName', {
                      required: 'Please Select Document Type to Verify'
                    })}
                    onChange={handleDocNameChange}
                    // defaultValue={kycStatusCheck ? motorRequest?.docName : ""}
                  >
                    <option value=''>Select Document Type</option>
                    {kycConfig?.KycFieldConfig.map(
                      (item) =>
                        item?.kycType === motorRequest?.kycType && (
                          <option
                            value={item?.docName}
                           
                            selected={
                              motorRequest?.docName === item?.docName
                                ? true
                                : false
                            }
                            onClick={() => reset('')}
                          >
                            {kycDocName[item.docName]}
                          </option>
                        )
                    )}
                  </select>
                  <label htmlFor='idxx4' className='did-floating-label'>
                    Select Document Type
                    <span className='text-danger'>*</span>
                  </label>
                </>
              </div>
              <p className='f-error text-danger'>{errors?.docName?.message}</p>
            </div>
          </div>

          {kycConfig &&
            kycFields &&
            kycFields.map(
              field =>
                watch('docName') === field?.docName &&
                field?.requiredField?.map((input) => {
                  if(["docLink","spouseName", "fatherName"].includes(input)) {
                    return null
                  }
                  return(
                  <div className='col-md-6 mb-3 mt-3 mt-md-0' key={input}>
                    {input === 'gender' ? (
                      <div className='did-floating-label-content'>
                        <select
                          name={input}
                          className='did-floating-input'
                          id={input}
                          {...register(input, {
                            required: `${input} is required`
                          })}
                        >
                          <option value='' className='text-capitalize'>
                            {input}
                          </option>
                          <option
                            value='M'
                            selected={
                              kycStatusCheck && motorRequest?.gender === 'M'
                            }
                          >
                            Male
                          </option>
                          <option
                            value='F'
                            selected={
                              kycStatusCheck && motorRequest?.gender === 'F'
                            }
                          >
                            Female
                          </option>
                          <option
                            value='O'
                            selected={
                              kycStatusCheck && motorRequest?.gender === 'O'
                            }
                          >
                            Other
                          </option>
                        </select>
                        <label
                          htmlFor={input}
                          className='did-floating-label text-capitalize'
                        >
                          {input}
                          <span className='text-danger'>*</span>
                        </label>
                        <p className='f-error text-danger'>
                          {errors?.[input]?.message}
                        </p>
                      </div>
                    ) : input === 'pincode' ? (
                      <div className='onwer-details'>
                        <div className='did-floating-label-content'>
                          <ReactSelect
                            options={pincodeData}
                            onChange={val => {
                              handleSelectPincode(val)
                            }}
                            placeholder={input}
                            className='form-select did-floating-input'
                            defaultValue={motorRequest?.pincode}
                          />
                          <label htmlFor='idxx4' className='did-floating-label'>
                            Select Pincode
                            <span className='text-danger'>*</span>
                          </label>
                          {pincodeError && (
                            <p className='f-error text-danger'>
                              Pincode is required
                            </p>
                          )}
                        </div>
                      </div>
                    ) : input === "uploadFrontPage" ? (
                      <div className="did-floating-label-content">
                      <input
                        type="file"
                        name="frontId"
                        className="did-floating-input"
                        accept={"image/*"}
                        onChange={(e) => handlePictureUpload(e)}
                        // {...register(input, {
                        //   required: `${input} is required`,
                        //   onChange: (e) => handleFileUpload(e),
                        // })}
                      />
                      <label
                        htmlFor={input}
                        className="did-floating-label text-capitalize"
                      >
                        {input}
                        <span className="text-danger">*</span>
                      </label>
                      {imageLinkError && (
                        <p className="f-error text-danger">
                          Document is required
                        </p>
                      )}
                    </div>
                    ) : input === "uploadBackPage" ? (
                      <div className="did-floating-label-content">
                      <input
                        type="file"
                        name="backId"
                        className="did-floating-input"
                        accept={"image/*"}
                        onChange={(e) => handlePictureUpload(e)}
                        // {...register(input, {
                        //   required: `${input} is required`,
                        //   onChange: (e) => handleFileUpload(e),
                        // })}
                      />
                      <label
                        htmlFor={input}
                        className="did-floating-label text-capitalize"
                      >
                        {input}
                        <span className="text-danger">*</span>
                      </label>
                      {imageLinkError && (
                        <p className="f-error text-danger">
                          Document is required
                        </p>
                      )}
                    </div>
                    ) : (
                      <div className='did-floating-label-content'>
                        <input
                          type={
                            input === 'dob'
                              ? 'date'
                              : input === 'docLink'
                              ? 'file'
                              : input === 'mobileNumber'
                              ? 'number'
                              : 'text'
                          }
                          className='did-floating-input'
                          {...register(input, {
                            required: `${input} is required`,
                            pattern:
                              input === 'docNumber'
                                ? {
                                    value: new RegExp(field.regexPattern),
                                    message: 'Invalid document number format'
                                  }
                                : input === 'mobileNumber'
                                ? {
                                    value: /^[1-9][0-9]{9}$/,
                                    maxLength: "10",
                                    message:
                                      'Mobile number must be 10 digits and cannot start with 0'
                                  }
                                : undefined
                          })}
                          defaultValue={
                            input === "firstName"
                              ? field?.requiredField?.includes("lastName")
                                ? motorRequest.middleName
                                  ? `${motorRequest.firstName} ${motorRequest.middleName} `
                                  : `${motorRequest.firstName}`
                                : motorRequest.middleName
                                ? `${motorRequest.firstName} ${motorRequest.middleName} ${motorRequest.lastName}`
                                : `${motorRequest.firstName} ${motorRequest.lastName}`
                              : input === "lastName"
                              ? `${motorRequest.lastName}`
                              : input === "dob"
                              ? motorRequest?.dob
                              : input === "docNumber"
                              ? motorRequest?.docNumber
                              : null
                          }
                          // defaultValue={
                          //   motorRequest?.customerType ===
                          //   CUSTOMER_TYPE.INDIVIDUAL
                          //     ? input === 'firstName'
                          //       ? motorRequest?.kycData?.fullName
                          //         ? motorRequest?.kycData?.fullName
                          //         : motorRequest?.middleName
                          //         ? `${motorRequest?.firstName} ${motorRequest?.middleName}`
                          //         : `${motorRequest?.firstName}`
                          //       : input === 'lastName'
                          //       ? `${motorRequest?.lastName}`
                          //       : input === 'dob'
                          //       ? motorRequest?.dob
                          //       : input === 'docNumber'
                          //       ? motorRequest?.docNumber
                          //       : null
                          //     : input === 'firstName'
                          //     ? motorRequest?.kycData?.fullName
                          //       ? motorRequest?.kycData?.fullName
                          //       : motorRequest?.middleName
                          //       ? `${motorRequest?.firstName} ${motorRequest?.middleName} ${motorRequest?.lastName}`
                          //       : `${motorRequest?.firstName} ${motorRequest?.lastName}`
                          //     : input === 'lastName'
                          //     ? `${motorRequest?.lastName}`
                          //     : input === 'dob'
                          //     ? motorRequest?.dob
                          //     : input === 'docNumber'
                          //     ? motorRequest?.docNumber
                          //     : null
                          // }
                          maxLength={input === 'mobileNumber' ? 10 : 25}
                          name={input}
                          onChange={handleInputChange}
                        />
                        <label
                          htmlFor={input}
                          className='did-floating-label text-capitalize'
                        >
                          {input === 'docNumber' ? field.label : input}
                          <span className='text-danger'>*</span>
                        </label>
                        <p className='f-error text-danger text-capitalize'>
                          {errors?.[input]?.message}
                        </p>
                      </div>
                    ) }
                  </div>
                  );
                  })
            )}
            
            {kycConfig &&
            kycFields &&
            kycFields.some(
              (field) =>
                field.requiredField.includes("fatherName") ||
                kycFields.some((field) =>
                  field.requiredField.includes("spouseName")
                )
            ) && (
              <div className="col-md-6 mb-2">
                <div className="did-floating-label-content">
                  <select
                    name="maritalStatus"
                    className="did-floating-input"
                    id="maritalStatus"
                    onChange={handleMaritalStatus}
                    {...register("maritalStatus", {
                      required: "Marital Status is required",
                    })}
                    defaultValue={motorRequest?.maritalStatus}
                  >
                    <option value="">Marital Status</option>
                    <option value="married">Married</option>
                    <option value="unmarried">Unmarried</option>
                  </select>
                  <label htmlFor="maritalStatus" className="did-floating-label">
                    Marital Status
                    <span className="text-danger">*</span>
                  </label>
                  <p className="f-error text-danger">
                    {errors?.maritalStatus?.message}
                  </p>
                </div>
              </div>
            )}

          {watch("maritalStatus") === "married" &&
            kycFields.some((field) =>
              field.requiredField.includes("spouseName")
            ) && (
              <div className="col-md-6 mb-2">
                <div className="did-floating-label-content">
                  <input
                    type="text"
                    className="did-floating-input"
                    {...register("spouseName", {
                      required: "Spouse's name is required",
                    })}
                    defaultValue={motorRequest?.spouseName}
                  />
                  <label htmlFor="spouseName" className="did-floating-label">
                    Spouse's Name
                    <span className="text-danger">*</span>
                  </label>
                  <p className="f-error text-danger">
                    {errors?.spouseName?.message}
                  </p>
                </div>
              </div>
            )}

          {/* Conditional rendering for fatherName based on marital status */}
          {watch("maritalStatus") === "unmarried" &&
            kycFields.some((field) =>
              field.requiredField.includes("fatherName")
            ) && (
              <div className="col-md-6 mb-2">
                <div className="did-floating-label-content">
                  <input
                    type="text"
                    className="did-floating-input"
                    {...register("fatherName", {
                      required: "Father's name is required",
                    })}
                    defaultValue={motorRequest?.fatherName}
                  />
                  <label htmlFor="fatherName" className="did-floating-label">
                    Father's Name
                    <span className="text-danger">*</span>
                  </label>
                  <p className="f-error text-danger">
                    {errors?.fatherName?.message}
                  </p>
                </div>
              </div>

            )}

          {watch("maritalStatus") === "married" &&
            kycFields.some((field) =>
              field.requiredField.includes("spouseName")
            ) && (
              <div className="col-md-6 mb-2">
                <div className="did-floating-label-content">
                  <input
                    type="text"
                    className="did-floating-input"
                    {...register("spouseName", {
                      required: "Spouse's name is required",
                    })}
                    defaultValue={motorRequest?.spouseName}
                  />
                  <label htmlFor="spouseName" className="did-floating-label">
                    Spouse's Name
                    <span className="text-danger">*</span>
                  </label>
                  <p className="f-error text-danger">
                    {errors?.spouseName?.message}
                  </p>
                </div>
              </div>
            )}

          {/* Conditional rendering for fatherName based on marital status */}
          {watch("maritalStatus") === "unmarried" &&
            kycFields.some((field) =>
              field.requiredField.includes("fatherName")
            ) && (
              <div className="col-md-6 mb-2">
                <div className="did-floating-label-content">
                  <input
                    type="text"
                    className="did-floating-input"
                    {...register("fatherName", {
                      required: "Father's name is required",
                    })}
                    defaultValue={motorRequest?.fatherName}
                  />
                  <label htmlFor="fatherName" className="did-floating-label">
                    Father's Name
                    <span className="text-danger">*</span>
                  </label>
                  <p className="f-error text-danger">
                    {errors?.fatherName?.message}
                  </p>
                </div>
              </div>

            )}

          {kycConfig?.kycConfig?.kycStep !== 'in_proposal' &&
            motorRequest?.kycStatus !== 'completed' &&
            kycConfig?.kycConfig?.kycStep !== 'after_proposal' && (
              <div className='col-md-12'>
                <div className='next-btn-btn d-flex gap-5'>
                  <button
                    type='button'
                    className='btn btn-primary mb-5 mt-5 py-3 w-100 fs-2 fw-bold'
                    onClick={() => handlePreviousPage()}
                  >
                    Back to Quotation
                  </button>
                  <button
                    type={
                      selectedPlan?.Api_name === insurers.Digit.name ||
                      selectedPlan?.Api_name === insurers.TATA.name
                        ? 'button'
                        : 'submit'
                    }
                    className='btn btn-primary mb-5 mt-5 py-3 w-100 fs-2 fw-bold'
                    // onClick={() => handleNextStep()}
                    onClick={() => {
                      redirection ? getKycStatus() : null;
                    }}
                  >
                    {proceedSpinner ? (
                      <div
                        class='spinner-border text-light'
                        role='status'
                      ></div>
                    ) : (
                      'Complete KYC'
                    )}
                  </button>
                </div>
              </div>
            )}

          {(kycConfig?.kycConfig?.kycStep === 'in_proposal' ||
            kycConfig?.kycConfig?.kycStep === 'after_proposal' ||
            motorRequest?.kycStatus === 'completed') && (
            <>
              <div className='col-md-6 mb-2'>
                <div className='onwer-details'>
                  <div className='did-floating-label-content'>
                    <select
                      name='maritalStatus'
                      className='did-floating-input'
                      id='maritalStatus'
                      // {...register("maritalStatus", {
                      //   required: "Marital Status is required",
                      // })}
                      onChange={handleMaritalStatus}
                    >
                      <option value=''>Marital Status</option>
                      <option value='married'>Married</option>
                      <option value='unmarried'>Unmarried</option>
                    </select>
                    <label htmlFor='idxx4' className='did-floating-label'>
                      Marital Status
                      <span className='text-danger'>*</span>
                    </label>
                    <p className='f-error text-danger'>
                      {errors?.maritalStatus?.message}
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-md-6 mb-2'>
                <div className='onwer-details'>
                  <div className='did-floating-label-content'>
                    <input
                      type='text'
                      className=' did-floating-input'
                      placeholder=''
                      {...register('addressLine1', {
                        required: 'Address 1 is required'
                      })}
                      value={address.addressLine1}
                      name='addressLine1'
                      onChange={handleAddressChange}
                      defaultValue={motorRequest?.addressLine1}
                      maxLength={30}

                      //   onChange={(e) => handleSelectAddress1(e.target.value)}
                    />
                    <label htmlFor='idxx4' className='did-floating-label'>
                      Address 1<span className='text-danger'>*</span>
                    </label>
                    <p className='f-error text-danger'>
                      {addressErrors.addressLine1}
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-md-6 mb-2'>
                <div className='onwer-details'>
                  <div className='did-floating-label-content'>
                    <input
                      type='text'
                      className=' did-floating-input'
                      placeholder=''
                      name='addressLine2'
                      onChange={handleAddressChange}
                      defaultValue={motorRequest?.addressLine2}
                      maxLength={30}
                    />
                    <label htmlFor='idxx4' className='did-floating-label'>
                      Address 2<span className='text-danger'>*</span>
                    </label>
                    <p className='f-error text-danger'>
                      {addressErrors.addressLine2}
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-md-6 mb-2'>
                <div className='onwer-details'>
                  <div className='did-floating-label-content'>
                    <input
                      type='text'
                      className=' did-floating-input'
                      placeholder=''
                      name='addressLine3'
                      onChange={handleAddressChange}
                      defaultValue={motorRequest?.addressLine3}
                      maxLength={30}
                    />
                    <label htmlFor='idxx4' className='did-floating-label'>
                      Address 3<span className='text-danger'>*</span>
                    </label>
                    <p className='f-error text-danger'>
                      {addressErrors.addressLine3}
                    </p>
                  </div>
                </div>
              </div>

              {/* <div className='col-md-6 mb-2'>
                <div className='onwer-details'>
                  <div className='did-floating-label-content fs-4'>
                    <ReactSelect
                      options={pincodeData}
                      onChange={val => {
                        handleSelectPincode(val)
                      }}
                      placeholder='Select area pincode'
                      className='form-select did-floating-input'
                      defaultValue={motorRequest?.pincode}
                    />
                    <label htmlFor='idxx4' className='did-floating-label'>
                      Select Pincode
                      <span className='text-danger'>*</span>
                    </label>
                    {pincodeError && (
                      <p className='f-error text-danger'>Pincode is required</p>
                    )}
                  </div>
                </div>
              </div> */}
              {kycFields &&
                !kycFields.some(
                  field =>
                    field.requiredField &&
                    field.requiredField.includes('pincode')
                ) && (
                  <div className='col-md-6 mb-2'>
                    <div className='onwer-details'>
                      <div className='did-floating-label-content fs-4'>
                        <ReactSelect
                          options={pincodeData}
                          onChange={val => handleSelectPincode(val)}
                          placeholder='Select area pincode'
                          className='form-select did-floating-input'
                          defaultValue={motorRequest?.pincode}
                        />
                        <label htmlFor='idxx4' className='did-floating-label'>
                          Select Pincode
                          <span className='text-danger'>*</span>
                        </label>
                        {pincodeError && (
                          <p className='f-error text-danger'>
                            Pincode is required
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                )}

              <div className='col-md-6 mb-2'>
                <div className='onwer-details'>
                  <div className='did-floating-label-content'>
                    <input
                      type='text'
                      placeholder=''
                      className='did-floating-input'
                      {...register('city', {
                        required: 'City is required'
                      })}
                      disabled
                      defaultValue={motorRequest?.city}
                    />
                    <label
                      htmlFor='idxx4'
                      className='did-floating-label uppercase'
                    >
                      Enter City
                      <span className='text-danger'>*</span>
                    </label>
                    <p className='f-error text-danger'>{addressErrors.city}</p>
                  </div>
                </div>
              </div>
              <div className='col-md-6 mb-2'>
                <div className='onwer-details'>
                  <div className='did-floating-label-content'>
                    <input
                      list='text'
                      placeholder=''
                      name='State'
                      {...register('state', {
                        required: 'State is required'
                      })}
                      defaultValue={motorRequest?.state}
                      disabled
                      className='did-floating-input uppercase'
                    />
                    <label htmlFor='idxx4' className='did-floating-label'>
                      Enter State
                      <span className='text-danger'>*</span>
                    </label>
                    <p className='f-error text-danger'>{addressErrors.state}</p>
                  </div>
                </div>
              </div>
              <div className='col-md-12 d-flex gap-4'>
                <button
                  type='button'
                  className='btn btn-primary mb-5 mt-5 py-3 w-50 fs-2 fw-bold'
                  onClick={() => handlePreviousPage()}
                >
                  Back to Quotation
                </button>
                <div className='next-btn-btn'>
                  <button
                    type='submit'
                    onClick={
                      kycConfig?.kycConfig?.kycStep === 'in_proposal' ||
                      kycConfig?.kycConfig?.kycStep === 'after_proposal'
                        ? null
                        : handleNextClick
                    }
                    className='btn btn-primary mb-5 mt-5 py-3 w-50 fs-2 fw-bold'
                  >
                    Next
                  </button>
                </div>
              </div>
            </>
          )}
        </div>

        <></>
      </div>
      {redirectionModal && (
        <RedirectionModal
          show={redirectionModal}
          setModal={setRedirectionModal}
          url={redirectionUrl}
        />
      )}
    </form>
  )
}

export default KYC
