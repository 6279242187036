import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom'
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  NavItem
} from 'reactstrap'
import {
  dispatchKycConfig,
  dispatchMotorQuote,
  dispatchProcessFlowMotor,
  dispatchQuickQuote,
  selectedPlanAction
} from '../../../../store/actions/userActions'
import {
  Documents,
  DocumentsNewCase
} from '../../../common/Homepage/MakeModelArr'
import {
  bajajIssueApi,
  consentService,
  digitCreateQuoteApiCall,
  digitPaymentApi,
  FutureGeneralInspection,
  getPreviousJourneyDetails,
  magmaPaymentcall,
  magmaProposalStatuscall,
  paymentTata,
  processFlow
} from '../../../common/Homepage/TPApiCall'
import Layout from '../../../common/Layout'
import { sendSuccessInfo } from '../../../Services/PosService'
import FutureGeneralPayment from '../../Additional/FutureGeneralPayment'
import HDFCPayment from '../../Additional/HDFCPayment'
import KotakPayment from '../../Additional/KotakPayment'
import LibertyPayment from '../../Additional/LibertyPayment'
import RoyalSundaramPayment from '../../Additional/RoyalSundaramPayment'
import ShriramPayment from '../../Additional/ShriramPaymentIntegration'
import ZunoPayment from '../../Additional/ZunoPayment'
import { useForm } from 'react-hook-form'
import SharePaymentQuotationModal from '../../../common/Homepage/SharePaymentQuotationModal'
import {
  caseStatus,
  decryptAes256Text,
  extractCustomerLeadId
} from '../../../utility/constants'
import { Worker, Viewer } from '@react-pdf-viewer/core'
import '@react-pdf-viewer/core/lib/styles/index.css'
const PolicyOverview = () => {
  const { register, errors } = useForm()
  const apiRequestQQ = useSelector(state => state.root.apiRequestQQ)
  const selectedPlan = useSelector(state => state.root.selectedPlan)
  const QuickQouteResult = useSelector(state => state.root.QuickQouteResult)
  const [images, setImages] = useState([])
  const [responsePolicyStatus, setResponsePolicyStatus] = useState('')
  const [magmaPaymentURL, setMagmaPaymentURL] = useState('')
  const [disabled, setDisabled] = useState('')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [magmaSpinner, setMagmaSpinner] = useState(false)
  const [modalOpen2, setModalOpen2] = useState(false)
  const [modalOpen3, setModalOpen3] = useState(false)
  const [modalOpen4, setModalOpen4] = useState(false)
  const motorRequest = useSelector(state => state.root.motorRequest)
  const [sharePaymentModal, setSharePaymentModal] = useState(false)
  const toggleSharePaymentModal = () => setSharePaymentModal(!sharePaymentModal)
  const [extractUrlCustomerLeadId, setExtractUrlCustomerLeadId] =
    useState(false)

  const [consentModal, setConsentModal] = useState(false)
  const toggleConsentModal = () => setConsentModal(!consentModal)
  const [cisDoc, setCisDoc] = useState('')
  const [checkCis, setCheckCis] = useState(false)
  const [isChecked, setIsChecked] = useState(false);
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (motorRequest?.isCisServiseCall) {
      setLoading(true); // Start loader
      consentService(motorRequest?.caseId)
        .then((res) => {
          if (res?.data?.caseStatus === "Policy CIS Doc Success") {
            const decryptCisDocumentUrl = decryptAes256Text(
              res?.data?.policyDocumentUrl,
              process.env.REACT_APP_DECRYPTION_KEY
            );
            setCisDoc(decryptCisDocumentUrl);
            setConsentModal(true);
          }
        })
        .catch((error) => {
          console.error("Error fetching CIS document:", error);
        })
        .finally(() => {
          setLoading(false); // Stop loader
        });
    }
  }, [])
  useEffect(() => {
    const urlCustomerLeadId = extractCustomerLeadId(location?.pathname)
    setExtractUrlCustomerLeadId(true)
    dispatchMotorQuote('customerLeadId', urlCustomerLeadId)
    // const now = new Date();
    // // Set the cookie to expire in 2 hours
    // const expires = new Date(now.getTime() + 2 * 60 * 60 * 1000);
    // setCookie("customerLeadId", urlCustomerLeadId, { expires });

    getPreviousJourneyDetails({ customerLeadId: urlCustomerLeadId })
      .then(response => {
        const stepsData = response?.data?.stepsData

        if (
          (response?.data?.processStep === 'step8' &&
            response?.data?.processDiscription === 'kycAndUserDetails') ||
          (response?.data?.processStep === 'step9' &&
            response?.data?.processDiscription === 'nomineeDetails') ||
          (response?.data?.processStep === 'step10' &&
            response?.data?.processDiscription === 'vehicleDetails') ||
          (response?.data?.processStep === 'step11' &&
            response?.data?.processDiscription === 'checkoutPage')
        ) {
          updateJourneyData(
            stepsData,
            response?.data?.processStep,
            response?.data?.processDiscription
          )
        } else if (
          response?.data?.processStep === 'step13' &&
          response?.data?.processDiscription === 'pdfGeneration'
        ) {
          const caseId = stepsData?.step13?.motorRequest?.caseId
          for (let key in stepsData?.step13?.motorRequest) {
            dispatchMotorQuote(`${key}`, stepsData?.step13?.motorRequest[key])
          }
          navigate(`/success-page/${urlCustomerLeadId}?caseId=${caseId}`)
        }
      })
      .catch(error => {
        console.error('Error fetching previous journey details:', error)
      })

    // if (
    //   motorRequest?.businessType === "new" ||
    //   motorRequest?.newPolicyType === POLICY_TYPE?.THIRDPARTY ||
    //   motorRequest?.isOwnerChanged
    // ) {
    //   motorQuotation(motorRequest);
    // }
  }, [])

  console.log('isCisServiseCall', motorRequest?.isCisServiseCall)

  const updateJourneyData = (stepsData, processStep, processDiscription) => {
    try {
      dispatchKycConfig(stepsData?.step8?.kycConfig)
      dispatchProcessFlowMotor('step8', stepsData?.step8)
      // dispatchMotorQuote(
      //   "registrationNumber",
      //   stepsData?.step1?.registrationNumber.replace(/-/g, "")
      // );
      // dispatchMotorQuote(
      //   "rtoCode",
      //   stepsData?.step1?.registrationNumber.replace(/-/g, "").substring(0, 4)
      // );
      // dispatchMotorQuote("vehicleType", stepsData?.step1?.vehicleType);

      // dispatchMotorQuote("makeId", stepsData?.step2?.make?.value || "");
      // dispatchMotorQuote("makeName", stepsData?.step2?.make?.label || "");
      // dispatchMotorQuote("modelId", stepsData?.step3?.model?.value || "");
      // dispatchMotorQuote("modelName", stepsData?.step3?.model?.label || "");
      // dispatchMotorQuote(
      //   "variantId",
      //   stepsData?.step4?.variantSelection?.value || ""
      // );
      // dispatchMotorQuote(
      //   "variantName",
      //   stepsData?.step4?.variantSelection?.label || ""
      // );
      // dispatchMotorQuote(
      //   "fuelType",
      //   stepsData?.step4?.variantSelection?.fuelType
      // );

      // for (let key in stepsData?.step5?.customerDetails) {
      //   dispatchUserDetails(`${key}`, stepsData?.step5?.customerDetails[key]);
      // }

      // let newData = {
      //   firstName: "",
      //   middleName: "",
      //   lastName: "",
      // };
      // const nameParts =
      //   stepsData?.step5?.customerDetails.customer_name.split(" ");

      // if (nameParts.length > 0) {
      //   newData.firstName = nameParts[0];
      // }

      // if (nameParts.length > 2) {
      //   newData.middleName = nameParts[1];
      // }

      // if (nameParts.length > 1) {
      //   newData.lastName = nameParts[nameParts.length - 1];
      // }

      // for (let key in newData) {
      //   dispatchMotorQuote(`${key}`, newData[key]);
      // }
      // dispatchMotorQuote(
      //   "email",
      //   stepsData?.step5?.customerDetails?.customer_email
      // );
      // dispatchMotorQuote(
      //   "mobileNumber",
      //   stepsData?.step5?.customerDetails?.customer_phone
      // );

      // dispatchMotorQuote("customerType", stepsData?.step5?.customerType || "");
      // dispatchMotorQuote(
      //   "isOwnerChanged",
      //   stepsData?.step5?.isOwnerChanged || false
      // );
      // dispatchMotorQuote(
      //   "manufaturingDate",
      //   stepsData?.step5?.manufaturingDate || ""
      // );

      // for (let key in stepsData?.step6?.pypSelection) {
      //   dispatchMotorQuote(`${key}`, stepsData?.step6?.pypSelection[key]);
      // }

      // for (let key in stepsData?.step7?.previousClaimData) {
      //   dispatchMotorQuote(`${key}`, stepsData?.step7?.previousClaimData[key]);
      // }
      for (let key in stepsData?.step8?.kycAndUserDetails) {
        dispatchMotorQuote(`${key}`, stepsData?.step8?.kycAndUserDetails[key])
      }
      for (let key in stepsData?.step10?.motorRequest) {
        dispatchMotorQuote(`${key}`, stepsData?.step10?.motorRequest[key])
      }

      for (let key in stepsData?.step10?.vehicleDetails) {
        dispatchMotorQuote(`${key}`, stepsData?.step10?.vehicleDetails[key])
      }
      dispatch(selectedPlanAction(stepsData?.step8?.planDetails))

      if (processStep === 'step11' && processDiscription === 'checkoutPage') {
        const caseId = stepsData?.step11?.motorRequest?.caseId
        for (let key in stepsData?.step11?.motorRequest) {
          dispatchMotorQuote(`${key}`, stepsData?.step11?.motorRequest[key])
        }
        for (let key in stepsData?.step11?.documents) {
          setImagesLink(prev => ({
            ...prev,
            [key]: stepsData?.step11?.documents[key]
          }))
        }
      } else if (
        processStep === 'step10' &&
        processDiscription === 'vehicleDetails'
      ) {
        if (
          stepsData?.step10?.vehicleDetails?.proposalStatus !==
          caseStatus.proposalSuccess
        ) {
          navigate(
            `/proposal/${stepsData?.step10?.motorRequest?.customerLeadId}`
          )
        }
        dispatch(selectedPlanAction(stepsData?.step10?.planDetails))
      } else if (
        processStep === 'step13' &&
        processDiscription === 'pdfGeneration'
      ) {
        const caseId = stepsData?.step13?.motorRequest?.caseId
        for (let key in stepsData?.step13?.motorRequest) {
          dispatchMotorQuote(`${key}`, stepsData?.step13?.motorRequest[key])
        }
        navigate(`/success-page/${urlCustomerLeadId}?caseId=${caseId}`)
      }
    } catch (error) {
      console.error('Error fetching previous journey details:', error)
    }
  }

  useEffect(() => {
    if (selectedPlan.Api_name === 'Shriram') {
      let index = QuickQouteResult.findIndex(
        item => item.Api_name === 'Shriram'
      )
      dispatch(selectedPlanAction(QuickQouteResult[index]))
    }
    dispatchQuickQuote('PaymentAmount', selectedPlan.FinalPremium)
  }, [QuickQouteResult])

  useEffect(() => {
    if (selectedPlan.Api_name === 'Magma') {
      setMagmaSpinner(true)
      magmaPaymentcall(apiRequestQQ).then(response => {
        console.log('response in magma proposal status', response)
        if (response?.data?.ServiceResult === 'Success') {
          setMagmaSpinner(false)
          setMagmaPaymentURL(response?.data?.OutputResult?.PaymentURL)
        } else if (response?.data?.ServiceResult === 'Failure') {
          alert('Your case is going for approval !!')
        }
      })
    }
  }, [apiRequestQQ?.ProposalId])

  useEffect(() => {
    if (selectedPlan.Api_name === 'digit') {
      digitCreateQuoteApiCall(apiRequestQQ)
        .then(response => {
          if (
            response.status === true &&
            response.data.error.httpCode === 200
          ) {
            dispatchQuickQuote('applicationId', response.data.applicationId)
            dispatchQuickQuote('ApiId', response.data.policyNumber)
            setResponsePolicyStatus(response?.data?.policyStatus)
            savePolicy(response.data.policyNumber)
            // if (
            //   response.data.policyStatus === "INCOMPLETE" &&
            //   apiRequestQQ.PolicyStatus == "continue"
            // ) {
            //   digitPaymentApi(response.data.applicationId);
            // } else if (
            //   response.data.policyStatus != "INCOMPLETE" &&
            //   apiRequestQQ.PolicyStatus != "continue"
            // ) {
            //   sendSuccessInfo(
            //     "Complete your vehicle incepection once insurer send you verification link via SMS, Find inscpection status in my inspections"
            //   );
            //   setTimeout(() => {
            //     navigate("/");
            //   }, 2000);
            // }
          } else {
            sendErrorMessage(response)
            setDisabled('')
          }
          setDisabled('')
        })
        .catch(err => {
          console.log(err)
          setDisabled('')
        })
    }
  }, [])

  const createQuote = async e => {
    e.preventDefault()
    setDisabled('disabled')

    if (motorRequest?.isVehicleNew) {
      if (!images.some(image => image.column === 'invoice')) {
        toast.error('Please upload Invoice Cover')
      } else {
        window.location.href = motorRequest?.paymentUrl

        const dataForFlow = {
          ...processFlowObjMotor,
          customerLeadId: motorRequest?.customerLeadId,
          processDiscription: 'paymentGateway',
          step: 'step12',
          step12: {
            motorRequest: { ...motorRequest },
            paymentStatus: caseStatus.paymentRequested
          }
        }
        processFlow(dataForFlow)
      }
    } else {
      if (
        !images.some(image => image.column === 'rc_front') &&
        !images.some(image => image.column === 'insurance_01')
      ) {
        toast.error('Please upload RC Front and Insurance 01')
      } else {
        window.location.href = motorRequest?.paymentUrl

        const dataForFlow = {
          ...processFlowObjMotor,
          customerLeadId: motorRequest?.customerLeadId,
          processDiscription: 'paymentGateway',
          step: 'step12',
          step12: {
            motorRequest: { ...motorRequest },
            paymentStatus: caseStatus.paymentRequested
          }
        }
        processFlow(dataForFlow)
      }
    }
    setDisabled('')
  }

  // if (
  //   apiRequestQQ?.PolicyStatus === "continue" &&
  //   apiRequestQQ.IsVehicleNew !== true
  // ) {
  //   if (
  //     !images.some((image) => image.column === "rc_front") &&
  //     !images.some((image) => image.column === "insurance_01")
  //   ) {
  //     toast.error("Please upload RC Front and Insurance 01");
  //   } else if (!images.some((image) => image.column === "insurance_01")) {
  //     toast.error("Insuracne 01 is required");
  //   } else if (!images.some((image) => image.column === "rc_front")) {
  //     toast.error("RC Front is required");
  //   } else {
  //     // if (selectedPlan.Api_name === "bajaj") {
  //     //   bajajIssueApi(apiRequestQQ);
  //     //   setTimeout(() => {
  //     //     setDisabled("");
  //     //   }, 10000);
  //     // }
  //     if (
  //       selectedPlan.Api_name === "Bajaj Allianz General Insurance Co. Ltd."
  //     ) {
  //       setMagmaSpinner(true);

  //       window.location.href = motorRequest?.paymentUrl;
  //     } else if (selectedPlan.Api_name === "Magma") {
  //       window.location.href = magmaPaymentURL;
  //     } else if (
  //       selectedPlan.Api_name === "Tata AIG General Insurance Co. Ltd." ||
  //       "ICICI Lombard General Insurance Co. Ltd." ||
  //       "HDFC" ||
  //       "Zurich Kotak General Insurance Company (India) Limited" ||
  //       "Future Generali India Insurance Company Limited"
  //     ) {
  //       setMagmaSpinner(true);

  //       paymentTata("motor/payment/" + motorRequest.caseId);
  //     } else if (selectedPlan.Api_name === "Zuno") {
  //       setMagmaSpinner(true);

  //       window.location.href = apiRequestQQ.zunoUrl;

  //       setMagmaSpinner(false);

  //       setTimeout(() => {
  //         setDisabled("");
  //       }, 10000);
  //     } else if (selectedPlan.Api_name == "Future") {
  //       FutureGeneralInspection(apiRequestQQ).then((response) => {
  //         if (response.status === true) {
  //           const data = response.data;
  //           console.log("inspection data", data);
  //           savePolicy(null, data.data.refId);

  //           if (
  //             data.status.code != 200 &&
  //             data.data.status != "company-approved"
  //           ) {
  //             sendSuccessInfo(
  //               "Complete your vehicle incepection once insurer send you verification link via SMS, Find inscpection status in my inspections"
  //             );

  //             setTimeout(() => {
  //               navigate("/");
  //             }, 2000);
  //           } else {
  //             alert("Inspection Done");
  //           }
  //         }
  //       });
  //     }
  //     if (selectedPlan.Api_name === "digit") {
  //       digitCreateQuoteApiCall(apiRequestQQ)
  //         .then((response) => {
  //           if (
  //             response.status === true &&
  //             response.data.error.httpCode === 200
  //           ) {
  //             dispatchQuickQuote(
  //               "applicationId",
  //               response.data.applicationId
  //             );
  //             dispatchQuickQuote("ApiId", response.data.policyNumber);
  //             savePolicy(response.data.policyNumber);
  //             if (
  //               response.data.policyStatus === "INCOMPLETE" &&
  //               (apiRequestQQ.PolicyStatus === "continue" ||
  //                 apiRequestQQ.PolicyStatus === "expired within 90 day" ||
  //                 apiRequestQQ.PolicyStatus === "expired above 90 day" ||
  //                 apiRequestQQ.PolicyStatus === "false")
  //             ) {
  //               digitPaymentApi(
  //                 response.data.applicationId,
  //                 apiRequestQQ?.ApiUniqueNumber?.Digit
  //               );
  //             } else if (
  //               response.data.policyStatus !== "INCOMPLETE" &&
  //               apiRequestQQ.PolicyStatus !== "continue"
  //             ) {
  //               sendSuccessInfo(
  //                 "Complete your vehicle incepection once insurer send you verification link via SMS, Find inscpection status in my inspections"
  //               );
  //               setTimeout(() => {
  //                 navigate("/");
  //               }, 2000);
  //             }
  //           } else {
  //             sendErrorMessage(response);
  //             setDisabled("");
  //           }
  //           setDisabled("");
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //           setDisabled("");
  //         });
  //     } else if (selectedPlan.Api_name === "Shriram") {
  //       // shriramGenerateProposal(apiRequestQQ, true);
  //     } else if (selectedPlan.Api_name === "Reliance") {
  //       relianceGenerateProposal(apiRequestQQ);
  //       setTimeout(() => {
  //         setDisabled("");
  //       }, 10000);
  //     }
  //   }
  // } else if (
  //   (apiRequestQQ?.PolicyStatus === "expired within 90 day" ||
  //     apiRequestQQ?.PolicyStatus === "expired above 90 day") &&
  //   apiRequestQQ.IsVehicleNew !== true
  // ) {
  //   if (!images.some((image) => image.column === "rc_front")) {
  //     // alert('Please upload RC Front')
  //     // setModalOpen4(!modalOpen4);
  //     toast.error("Please upload RC Front");
  //   } else {
  //     // if (selectedPlan.Api_name === "bajaj") {
  //     //   bajajIssueApi(apiRequestQQ);
  //     //   setTimeout(() => {
  //     //     setDisabled("");
  //     //   }, 10000);
  //     // }
  //     if (
  //       selectedPlan.Api_name === "Bajaj Allianz General Insurance Co. Ltd."
  //     ) {
  //       setMagmaSpinner(true);

  //       window.location.href = motorRequest?.paymentUrl;
  //     } else if (selectedPlan.Api_name === "Magma") {
  //       window.location.href = magmaPaymentURL;
  //     } else if (selectedPlan.Api_name === "Zuno") {

  //       const res = await zunoIssueApi(apiRequestQQ);

  //       console.log("resssss", res);

  //       if (res.status === true) {
  //         zunoPaymentcall(apiRequestQQ, res);
  //       }

  //       setTimeout(() => {
  //         setDisabled("");
  //       }, 10000);
  //     } else if (selectedPlan.Api_name == "Future") {
  //       FutureGeneralInspection(apiRequestQQ).then((response) => {
  //         if (response.status === true) {
  //           const data = response.data;
  //           console.log("inspection data", data);
  //           savePolicy(null, data.data.refId);

  //           if (
  //             data.status.code != 200 &&
  //             data.data.status != "company-approved"
  //           ) {
  //             sendSuccessInfo(
  //               "Complete your vehicle incepection once insurer send you verification link via SMS, Find inscpection status in my inspections"
  //             );

  //             setTimeout(() => {
  //               navigate("/");
  //             }, 2000);
  //           } else {
  //             alert("Inspection Done");
  //           }
  //         }
  //       });
  //     }
  //     if (selectedPlan.Api_name === "digit") {
  //       digitCreateQuoteApiCall(apiRequestQQ)
  //         .then((response) => {
  //           if (
  //             response.status === true &&
  //             response.data.error.httpCode === 200
  //           ) {
  //             dispatchQuickQuote(
  //               "applicationId",
  //               response.data.applicationId
  //             );
  //             dispatchQuickQuote("ApiId", response.data.policyNumber);
  //             savePolicy(response.data.policyNumber);
  //             if (
  //               response.data.policyStatus === "INCOMPLETE" &&
  //               (apiRequestQQ.PolicyStatus === "continue" ||
  //                 apiRequestQQ.PolicyStatus === "expired within 90 day" ||
  //                 apiRequestQQ.PolicyStatus === "expired above 90 day" ||
  //                 apiRequestQQ.PolicyStatus === "false")
  //             ) {
  //               digitPaymentApi(
  //                 response.data.applicationId,
  //                 apiRequestQQ?.ApiUniqueNumber?.Digit
  //               );
  //             } else if (
  //               response.data.policyStatus !== "INCOMPLETE" &&
  //               apiRequestQQ.PolicyStatus !== "continue"
  //             ) {
  //               sendSuccessInfo(
  //                 "Complete your vehicle incepection once insurer send you verification link via SMS, Find inscpection status in my inspections"
  //               );
  //               setTimeout(() => {
  //                 navigate("/");
  //               }, 2000);
  //             }
  //           } else {
  //             sendErrorMessage(response);
  //             setDisabled("");
  //           }
  //           setDisabled("");
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //           setDisabled("");
  //         });
  //     } else if (selectedPlan.Api_name === "Shriram") {
  //       // shriramGenerateProposal(apiRequestQQ, true);
  //     } else if (selectedPlan.Api_name === "Reliance") {
  //       relianceGenerateProposal(apiRequestQQ);
  //       setTimeout(() => {
  //         setDisabled("");
  //       }, 10000);
  //     }
  //   }
  // } else if (apiRequestQQ.IsVehicleNew === true) {
  //   if (!images.some((image) => image.column === "invoice_cover")) {
  //     toast.error("Please upload Invoice Cover");
  //   } else {
  //     // if (selectedPlan.Api_name === "bajaj") {
  //     //   bajajIssueApi(apiRequestQQ);
  //     //   setTimeout(() => {
  //     //     setDisabled("");
  //     //   }, 10000);
  //     // }
  //     if (
  //       selectedPlan.Api_name === "Bajaj Allianz General Insurance Co. Ltd."
  //     ) {
  //       setMagmaSpinner(true);

  //       window.location.href = motorRequest?.paymentUrl;
  //     } else if (selectedPlan.Api_name === "Magma") {
  //       window.location.href = magmaPaymentURL;
  //     } else if (
  //       selectedPlan.Api_name === "Bajaj Allianz General Insurance Co. Ltd."
  //     ) {
  //       setMagmaSpinner(true);

  //       window.open(motorRequest?.paymentUrl);
  //     } else if (
  //       selectedPlan.Api_name === "Tata AIG General Insurance Co. Ltd." ||
  //       "ICICI Lombard General Insurance Co. Ltd." ||
  //       "HDFC" ||
  //       "Zurich Kotak General Insurance Company (India) Limited" ||
  //       "Future Generali India Insurance Company Limited"
  //     ) {
  //       setMagmaSpinner(true);

  //       paymentTata("motor/payment/" + motorRequest.caseId);
  //     } else if (selectedPlan.Api_name === "Zuno") {
  //       setMagmaSpinner(true);

  //       window.location.href = apiRequestQQ.zunoUrl;

  //       setMagmaSpinner(false);

  //       setTimeout(() => {
  //         setDisabled("");
  //       }, 10000);
  //     } else if (selectedPlan.Api_name == "Future") {
  //       FutureGeneralInspection(apiRequestQQ).then((response) => {
  //         if (response.status === true) {
  //           const data = response.data;
  //           console.log("inspection data", data);
  //           savePolicy(null, data.data.refId);

  //           if (
  //             data.status.code != 200 &&
  //             data.data.status != "company-approved"
  //           ) {
  //             sendSuccessInfo(
  //               "Complete your vehicle incepection once insurer send you verification link via SMS, Find inscpection status in my inspections"
  //             );

  //             setTimeout(() => {
  //               navigate("/");
  //             }, 2000);
  //           } else {
  //             alert("Inspection Done");
  //           }
  //         }
  //       });
  //     }
  //     if (selectedPlan.Api_name === "digit") {
  //       digitCreateQuoteApiCall(apiRequestQQ)
  //         .then((response) => {
  //           if (
  //             response.status === true &&
  //             response.data.error.httpCode === 200
  //           ) {
  //             dispatchQuickQuote(
  //               "applicationId",
  //               response.data.applicationId
  //             );
  //             dispatchQuickQuote("ApiId", response.data.policyNumber);
  //             savePolicy(response.data.policyNumber);
  //             if (
  //               response.data.policyStatus === "INCOMPLETE" &&
  //               (apiRequestQQ.PolicyStatus === "continue" ||
  //                 apiRequestQQ.PolicyStatus === "expired within 90 day" ||
  //                 apiRequestQQ.PolicyStatus === "expired above 90 day" ||
  //                 apiRequestQQ.PolicyStatus === "false")
  //             ) {
  //               digitPaymentApi(
  //                 response.data.applicationId,
  //                 apiRequestQQ?.ApiUniqueNumber?.Digit
  //               );
  //             } else if (
  //               response.data.policyStatus !== "INCOMPLETE" &&
  //               apiRequestQQ.PolicyStatus !== "continue"
  //             ) {
  //               sendSuccessInfo(
  //                 "Complete your vehicle incepection once insurer send you verification link via SMS, Find inscpection status in my inspections"
  //               );
  //               setTimeout(() => {
  //                 navigate("/");
  //               }, 2000);
  //             }
  //           } else {
  //             sendErrorMessage(response);
  //             setDisabled("");
  //           }
  //           setDisabled("");
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //           setDisabled("");
  //         });
  //     } else if (selectedPlan.Api_name === "Shriram") {
  //       // shriramGenerateProposal(apiRequestQQ, true);
  //     } else if (selectedPlan.Api_name === "Reliance") {
  //       relianceGenerateProposal(apiRequestQQ);
  //       setTimeout(() => {
  //         setDisabled("");
  //       }, 10000);
  //     }
  //   }
  // } else {
  //   if (
  //     !images.some((image) => image.column === "rc_front") &&
  //     !images.some((image) => image.column === "insurance_01")
  //   ) {
  //     toast.error("Please upload RC Front and Insurance 01");
  //   } else if (!images.some((image) => image.column === "insurance_01")) {
  //     toast.error("Insuracne 01 is required");
  //   } else if (!images.some((image) => image.column === "rc_front")) {
  //     toast.error("RC Front is required");
  //   } else {
  //     if (selectedPlan.Api_name === "bajaj") {
  //       bajajIssueApi(apiRequestQQ);
  //       setTimeout(() => {
  //         setDisabled("");
  //       }, 10000);
  //     } else if (selectedPlan.Api_name === "Magma") {
  //       window.location.href = magmaPaymentURL;
  //     } else if (selectedPlan.Api_name === "Zuno") {
  //       setMagmaSpinner(true);

  //       window.location.href = apiRequestQQ.zunoUrl;

  //       setMagmaSpinner(false);

  //       setTimeout(() => {
  //         setDisabled("");
  //       }, 10000);
  //     } else if (selectedPlan.Api_name == "Future") {
  //       FutureGeneralInspection(apiRequestQQ).then((response) => {
  //         if (response.status === true) {
  //           const data = response.data;
  //           console.log("inspection data", data);
  //           savePolicy(null, data.data.refId);

  //           if (
  //             data.status.code != 200 &&
  //             data.data.status != "company-approved"
  //           ) {
  //             sendSuccessInfo(
  //               "Complete your vehicle incepection once insurer send you verification link via SMS, Find inscpection status in my inspections"
  //             );

  //             setTimeout(() => {
  //               navigate("/");
  //             }, 2000);
  //           } else {
  //             alert("Inspection Done");
  //           }
  //         }
  //       });
  //     }
  //     if (selectedPlan.Api_name === "digit") {
  //       digitCreateQuoteApiCall(apiRequestQQ)
  //         .then((response) => {
  //           if (
  //             response.status === true &&
  //             response.data.error.httpCode === 200
  //           ) {
  //             dispatchQuickQuote(
  //               "applicationId",
  //               response.data.applicationId
  //             );
  //             dispatchQuickQuote("ApiId", response.data.policyNumber);
  //             savePolicy(response.data.policyNumber);
  //             if (
  //               response.data.policyStatus === "INCOMPLETE" &&
  //               (apiRequestQQ.PolicyStatus === "continue" ||
  //                 apiRequestQQ.PolicyStatus === "expired within 90 day" ||
  //                 apiRequestQQ.PolicyStatus === "expired above 90 day" ||
  //                 apiRequestQQ.PolicyStatus === "false")
  //             ) {
  //               digitPaymentApi(
  //                 response.data.applicationId,
  //                 apiRequestQQ?.ApiUniqueNumber?.Digit
  //               );
  //             } else if (
  //               response.data.policyStatus !== "INCOMPLETE" &&
  //               apiRequestQQ.PolicyStatus !== "continue"
  //             ) {
  //               sendSuccessInfo(
  //                 "Complete your vehicle incepection once insurer send you verification link via SMS, Find inscpection status in my inspections"
  //               );
  //               setTimeout(() => {
  //                 navigate("/");
  //               }, 2000);
  //             }
  //           } else {
  //             sendErrorMessage(response);
  //             setDisabled("");
  //           }
  //           setDisabled("");
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //           setDisabled("");
  //         });
  //     } else if (selectedPlan.Api_name === "Shriram") {
  //       // shriramGenerateProposal(apiRequestQQ, true);
  //     } else if (selectedPlan.Api_name === "Reliance") {
  //       relianceGenerateProposal(apiRequestQQ);
  //       setTimeout(() => {
  //         setDisabled("");
  //       }, 10000);
  //     }
  //   }
  // }

  const savePolicy = (apiId, inspection_no) => {
    let data = { ...apiRequestQQ, ...selectedPlan }
    if (apiId) {
      data.ApiId = apiId
    }
    if (inspection_no) {
      data.inspection_no = inspection_no
    }
  }

  const handleUploadFile = (e, column) => {
    const FilterImg = images.filter(e => e.column !== column)
    const file = e.target.files[0]
    let arr = [...FilterImg]
    arr.push({
      column,
      image: file,
      preview: URL.createObjectURL(file)
    })
    setImages(arr)
  }

  useEffect(() => {
    console.log('images', images)
  }, [images])
  // const handleConsent = () =>{
  //   setConsentModal(!consentModal)
  //   setCheckCis(true)
  // } 
  const handleConsent = () => {
    if (!isChecked) {
      setShowError(true);
      setConsentModal(!consentModal) // Show error if checkbox is not checked
    } else {
      setShowError(false);
      // Call the provided handleConsent function
    }
  };
  const handleConsentBack = () => {
    navigate(`/quotelist/${motorRequest?.customerLeadId}`)
  }
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
    setShowError(false);
  };

  return (
    <Layout header2={true}>
      <section className='policyPUrView'>
        <div className='container-fluid px-lg-5 px-3'>
          <div className='row justify-content-between'>
            <div className='col-lg-5 col-12'>
              <div className='accordion' id='accordionExample'>
                <div className='accordion-item mb-4 border-0'>
                  <h2 className='accordion-header' id='headingOne'>
                    <button
                      className='accordion-button bg-primary text-white text-capitalize fs-2 fw-bold'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapseOne'
                      aria-expanded='true'
                      aria-controls='collapseOne'
                    >
                      Policy Details
                    </button>
                  </h2>
                  <div
                    id='collapseOne'
                    className='accordion-collapse collapse show'
                    aria-labelledby='headingOne'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      <div className='table-responsive p-3'>
                        <table className='table table-bordered table-striped fs-3'>
                          <tbody>
                            <tr>
                              <th className='w-50'>
                                <p className='mb-0'>IDV</p>
                              </th>
                              <th>
                                <p className='mb-0'>₹ {selectedPlan?.idv}</p>
                              </th>
                            </tr>
                            <tr>
                              <td>
                                <p className='mb-0'>Net Premium</p>
                              </td>
                              <td>
                                <p className='mb-0'>
                                  ₹{selectedPlan?.NetPremium}
                                </p>
                              </td>
                            </tr>
                            {selectedPlan?.OdTenure && (
                              <tr>
                                <th>OD Tenure</th>
                                <th>{selectedPlan?.OdTenure} Year</th>
                              </tr>
                            )}
                            {selectedPlan?.TpTenure && (
                              <tr>
                                <th>TP Tenure</th>
                                <th>{selectedPlan?.TpTenure} Years</th>
                              </tr>
                            )}
                            <tr>
                              <td>GST18%</td>
                              <td>{selectedPlan?.GST}</td>
                            </tr>
                            <tr>
                              <th>Policy Start Date</th>
                              <th>{selectedPlan?.StartDate}</th>
                            </tr>
                            <tr>
                              <td>Policy End Date</td>
                              <td>{selectedPlan?.EndDate}</td>
                            </tr>
                            <tr>
                              <th>
                                <p className='mb-0'>Final Premium</p>
                              </th>
                              <th>
                                <p className='mb-0'>
                                  {selectedPlan?.FinalPremium}
                                </p>
                              </th>
                            </tr>
                            {selectedPlan?.IsElectricalAccessories != false && (
                              <tr>
                                <th>Electrical Accessories Premium</th>
                                <td>
                                  {selectedPlan?.IsElectricalAccessories ==
                                    true ? (
                                    <span>(Included)</span>
                                  ) : (
                                    selectedPlan?.IsElectricalAccessories
                                  )}
                                </td>
                              </tr>
                            )}
                            {selectedPlan?.IsNonElectricalAccessories !=
                              false && (
                                <tr>
                                  <th>Non Electrical Accessories Premium</th>
                                  <td>
                                    {selectedPlan?.IsNonElectricalAccessories ==
                                      true ? (
                                      <span>(Included)</span>
                                    ) : (
                                      selectedPlan?.IsNonElectricalAccessories
                                    )}
                                  </td>
                                </tr>
                              )}
                            {selectedPlan?.RoadSideAssistance != false && (
                              <tr>
                                <th>Road Side Assistance</th>
                                <td>
                                  {' '}
                                  {selectedPlan?.RoadSideAssistance === true ? (
                                    <span>(Included)</span>
                                  ) : (
                                    selectedPlan?.RoadSideAssistance
                                  )}
                                </td>
                              </tr>
                            )}
                            {selectedPlan?.EngineProtection != false && (
                              <tr>
                                <th>Engine Protection</th>
                                <td>
                                  {' '}
                                  {selectedPlan?.EngineProtection === true ? (
                                    <span>(Included)</span>
                                  ) : (
                                    selectedPlan?.EngineProtection
                                  )}
                                </td>
                              </tr>
                            )}
                            {selectedPlan?.TyreProtection != false && (
                              <tr>
                                <th>Tyre Protection </th>
                                <td>
                                  {' '}
                                  {selectedPlan?.TyreProtection === true ? (
                                    <span>(Included)</span>
                                  ) : (
                                    selectedPlan?.TyreProtection
                                  )}
                                </td>
                              </tr>
                            )}
                            {selectedPlan?.RimProtection != false && (
                              <tr>
                                <th>Rim Protection </th>
                                <td>
                                  {selectedPlan?.RimProtection === true ? (
                                    <span>(Included)</span>
                                  ) : (
                                    selectedPlan?.RimProtection
                                  )}
                                </td>
                              </tr>
                            )}
                            {selectedPlan?.Consumables != false && (
                              <tr>
                                <th>Consumables </th>
                                <td>
                                  {selectedPlan?.Consumables === true ? (
                                    <span>(Included)</span>
                                  ) : (
                                    selectedPlan?.Consumables
                                  )}
                                </td>
                              </tr>
                            )}
                            {selectedPlan?.PersonalAccident != false && (
                              <tr>
                                <th>Personal Accident </th>
                                <td>
                                  {selectedPlan?.PersonalAccident === true ? (
                                    <span>(Included)</span>
                                  ) : (
                                    selectedPlan?.PersonalAccident
                                  )}
                                </td>
                              </tr>
                            )}
                            {selectedPlan?.InvoiceCover != false && (
                              <tr>
                                <th>Invoice Cover </th>
                                <td>
                                  {selectedPlan?.InvoiceCover === true ? (
                                    <span>(Included)</span>
                                  ) : (
                                    selectedPlan?.InvoiceCover
                                  )}
                                </td>
                              </tr>
                            )}
                            {/* {selectedPlan?.EngineGearBox != false && (
                              <tr>
                                <th>Engine GearBox </th>
                                <td>
                                  {selectedPlan?.EngineGearBox === true ? (
                                    <span>(Included)</span>
                                  ) : (
                                    selectedPlan?.EngineGearBox
                                  )}
                                </td>
                              </tr>
                            )} */}

                            {selectedPlan?.PassengerCover != false && (
                              <tr className='mb-2'>
                                <th>Passenger Cover </th>
                                <td>
                                  {selectedPlan?.PassengerCover === true ? (
                                    <span>(Included)</span>
                                  ) : (
                                    selectedPlan?.PassengerCover
                                  )}
                                </td>
                              </tr>
                            )}
                            {selectedPlan?.LossOfPersonalBelongings !=
                              false && (
                                <tr>
                                  <th>Loss Of PersonalBelongings </th>
                                  <td>
                                    {selectedPlan?.LossOfPersonalBelongings ===
                                      true ? (
                                      <span>(Included)</span>
                                    ) : (
                                      selectedPlan?.LossOfPersonalBelongings
                                    )}
                                  </td>
                                </tr>
                              )}
                            {selectedPlan?.ZeroDepriciationCover != false && (
                              <tr>
                                <th>Zero DepriciationCover </th>
                                <td>
                                  {selectedPlan?.ZeroDepriciationCover ===
                                    true ? (
                                    <span>(Included)</span>
                                  ) : (
                                    selectedPlan?.ZeroDepriciationCover
                                  )}
                                </td>
                              </tr>
                            )}
                            {selectedPlan?.KeyReplacement != false && (
                              <tr>
                                <th>KeyReplacement </th>
                                <td>
                                  {selectedPlan?.KeyReplacement === true ? (
                                    <span>(Included)</span>
                                  ) : (
                                    selectedPlan?.KeyReplacement
                                  )}
                                </td>
                              </tr>
                            )}
                            {selectedPlan?.NCBProtection != false && (
                              <tr>
                                <th>No Claim Protection </th>
                                <td>
                                  {selectedPlan?.GST === true ? (
                                    <span>(Included)</span>
                                  ) : (
                                    selectedPlan?.NCBProtection
                                  )}
                                </td>
                              </tr>
                            )}
                            {selectedPlan?.VoluntaryDeductive != false && (
                              <tr>
                                <th>Voluntary Deductive </th>
                                <td>
                                  {selectedPlan?.VoluntaryDeductive === true ? (
                                    <span>(Included)</span>
                                  ) : (
                                    selectedPlan?.VoluntaryDeductive
                                  )}
                                </td>
                              </tr>
                            )}
                            {selectedPlan?.NCBDiscount != false && (
                              <tr>
                                <th>NCB Discount </th>
                                <td>
                                  {selectedPlan?.NCBDiscount === true ? (
                                    <span>(Included)</span>
                                  ) : (
                                    selectedPlan?.NCBDiscount
                                  )}
                                </td>
                              </tr>
                            )}

                            {selectedPlan?.discount != false && (
                              <tr>
                                <th>OD Discount </th>
                                <td>
                                  {selectedPlan?.discount === true ? (
                                    <span>(Included)</span>
                                  ) : (
                                    selectedPlan?.discount
                                  )}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='accordion-item mb-4 border-0'>
                  <h2 className='accordion-header' id='headingTwo'>
                    <button
                      className='accordion-button collapsed bg-primary text-white text-capitalize fs-2 fw-bold'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapseTwo'
                      aria-expanded='false'
                      aria-controls='collapseTwo'
                    >
                      Policy holder Details
                    </button>
                  </h2>
                  <div
                    id='collapseTwo'
                    className='accordion-collapse collapse'
                    aria-labelledby='headingTwo'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      <div className='table-responsive p-3'>
                        <table className='table table-bordered table-striped fs-3'>
                          <tbody>
                            <tr>
                              <th>
                                <p className='mb-0'>Name</p>
                              </th>
                              <th>
                                <p className='mb-0'>
                                  {`${motorRequest?.firstName} ${motorRequest?.middleName
                                    ? motorRequest?.middleName
                                    : ''
                                    } ${motorRequest?.lastName}`}
                                </p>
                              </th>
                            </tr>
                            <tr>
                              <td>
                                <p className='mb-0'>D.O.B</p>
                              </td>
                              <td>
                                <p className='mb-0'>{motorRequest?.dob}</p>
                              </td>
                            </tr>
                            <tr>
                              <th>
                                <p className='mb-0'>Contact No.</p>
                              </th>
                              <th>
                                <p className='mb-0'>
                                  {motorRequest?.mobileNumber}
                                </p>
                              </th>
                            </tr>
                            <tr>
                              <td>
                                <p className='mb-0'>Email Address</p>
                              </td>
                              <td>
                                <p className='mb-0'>{motorRequest?.email}</p>
                              </td>
                            </tr>
                            <tr>
                              <th>
                                <p className='mb-0'>Residential Address</p>
                              </th>
                              <th className='w-50'>
                                <p className='mb-0'>
                                  {`${motorRequest?.addressLine1} ${motorRequest?.addressLine2} ${motorRequest?.addressLine3}`}
                                </p>
                              </th>
                            </tr>
                            <tr>
                              <td>
                                <p className='mb-0'>State</p>
                              </td>
                              <td>
                                <p className='mb-0'>{motorRequest?.state}</p>
                              </td>
                            </tr>
                            <tr>
                              <th>
                                <p className='mb-0'>City</p>
                              </th>
                              <th>
                                <p className='mb-0'>{motorRequest?.city}</p>
                              </th>
                            </tr>
                            <tr>
                              <th>
                                <p className='mb-0'>Pincode</p>
                              </th>
                              <th>
                                <p className='mb-0'>{motorRequest?.pincode}</p>
                              </th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='accordion-item mb-4 border-0'>
                  <h2 className='accordion-header' id='headingThree'>
                    <button
                      className='accordion-button collapsed bg-primary text-white text-capitalize fs-2 fw-bold'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapseThree'
                      aria-expanded='false'
                      aria-controls='collapseThree'
                    >
                      Vehicle Details
                    </button>
                  </h2>
                  <div
                    id='collapseThree'
                    className='accordion-collapse collapse'
                    aria-labelledby='headingThree'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      <div className='table-responsive p-3'>
                        <table className='table table-bordered table-striped fs-3'>
                          <tbody>
                            <tr>
                              <th>
                                <p className='mb-0'>RTO Regisration No.</p>
                              </th>
                              <th>
                                <p className='mb-0'>
                                  {motorRequest?.registrationNumber}
                                </p>
                              </th>
                            </tr>
                            <tr>
                              <td>
                                <p className='mb-0'>RTO City</p>
                              </td>
                              <td>
                                <p className='mb-0'>
                                  {motorRequest?.registrationCity}
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <th>
                                <p className='mb-0'>Engine No.</p>
                              </th>
                              <th className='w-50'>
                                <p className='mb-0'>
                                  {motorRequest?.engineNumber}
                                </p>
                              </th>
                            </tr>
                            <tr>
                              <td>
                                <p className='mb-0'>Chassis No.</p>
                              </td>
                              <td>
                                <p className='mb-0'>
                                  {motorRequest?.chassisNumber}
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="accordion-item mb-4 border-0">
                  <h2 className="accordion-header" id="headingFour">
                    <button
                      className="accordion-button collapsed bg-primary text-white text-capitalize fs-2 fw-bold"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      Nominee Details
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="table-responsive p-3 ">
                        <table className="table table-bordered table-striped fs-3">
                          <tbody>
                            <tr>
                              <th className="w-50">
                                <p className="mb-0">Name</p>
                              </th>
                              <th>
                                <p className="mb-0">
                                  {motorRequest?.nomineeName}
                                </p>
                              </th>
                            </tr>
                            <tr>
                              <td>
                                <p className="mb-0">D.O.B</p>
                              </td>
                              <td>
                                <p className="mb-0">
                                  {motorRequest?.nomineeDob}
                                </p>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <p className="mb-0">Relationship</p>
                              </td>
                              <td>
                                <p className="mb-0">
                                  {apiRequestQQ?.NomineeRelationship}
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>

            <div className='col-lg-6 col-12'>
              <div className='d-sm-flex text-sm-start text-center justify-content-between align-items-center'>
                <div>
                  <img
                    src={selectedPlan.Logo}
                    style={{ height: '80px', width: '200px' }}
                    alt=''
                    className='d-inline-block m4-sm-0 my-4'
                  />
                </div>

                {/* <div>
                  <p className="fs-1 fw-bold mb-0">Vehicle Insurance</p>
                  <p className="fs-3 text-muted  fw-bold mb-0">
                    Policy ID :<span className>SQTW2022071316294689558</span>
                  </p>
                </div> */}
                <div>
                  <Link
                    to='/proposal'
                    className='btn btn-outline-light py-3 px-4 bg-white text-dark my-4 '
                    style={{ border: '1px solid #00b0cb' }}
                  >
                    <i className='fa fa-arrow-left fs-4' />
                  </Link>
                </div>
              </div>

              <div className='row mx-0 px-0 docpreimg mt-5'>
                {Documents &&
                  Documents.length > 0 &&
                  (motorRequest.isVehicleNew
                    ? DocumentsNewCase
                    : Documents
                  ).map((item, index) => (
                    <div className='col-lg-3 col-sm-3  col-6 mb-5'>
                      <input
                        type='file'
                        className='d-none'
                        id={'abc' + index}
                        name={item.name}
                        onChange={e => handleUploadFile(e, item.name)}
                        accept={item.type == 'file' ? 'image/*' : ''}
                      />

                      <label
                        htmlFor={'abc' + index}
                        className=''
                        style={{ cursor: 'pointer' }}
                      >
                        {images.find(img => img.column === item.name)
                          ?.preview ? (
                          <img
                            src={
                              images.find(img => img.column === item.name)
                                ?.preview
                            }
                            className='w-100 h-100 d-block'
                            alt=''
                            style={{ objectFit: 'contain' }}
                          />
                        ) : (
                          <img
                            src='/assets/images/Addimg.png'
                            className='w-100 h-100 d-block'
                            style={{ objectFit: 'contain' }}
                          />
                        )}
                      </label>
                      {/* <p className="f-error fs-4 mx-2">
                    {option.item?.message}
                  </p> */}

                      <p className='fs-2 text-decoration-underline fw-bold mt-3 mb-3'>
                        {item.option}
                      </p>
                    </div>
                  ))}

                <div className='col-12' />

                {(selectedPlan?.insurerId !== 14 || checkCis) && (
        <div className="col-sm-4 col-6 mx-auto ms-auto mr-lg-0 share-pay-btn">
          <div className="text-sm-end text-center mb-sm-0 mb-5 w-100">
            <button
              onClick={(e) => createQuote(e)}
              className="btn btn-primary fs-3 px-4 py-2 fw-bold"
            >
              <>
                Buy <span>{selectedPlan?.FinalPremium}</span>
              </>
            </button>
          </div>
          <button
            className="btn btn-primary fs-3 px-4 py-2 fw-bold"
            onClick={toggleSharePaymentModal}
          >
            Share Payment Link
          </button>
        </div>
      )}

               
              </div>
            </div>
          </div>

          <Modal
            backdrop='static'
            isOpen={modalOpen2}
            toggle={() => setModalOpen2(!modalOpen2)}
            size='md'
            className='modal-dialog-centered'
          >
            <ModalBody>
              <div className='modal-logo'>
                <a>
                  <img src='./assetss/images/LogoNeww.png'></img>
                </a>
              </div>
              <div className='modal_heading w-100 mb-3'>
                <h4 className='text-center fs-3 mb-4'>
                  {' '}
                  Please upload RC Front and Inusrance 01
                </h4>

                <div
                  onClick={() => setModalOpen2(!modalOpen2)}
                  class='v2_close false'
                >
                  <a>
                    <img
                      src='/assets/images/delete_icon.svg'
                      title='Close'
                      alt='close'
                    />
                  </a>
                </div>
              </div>
              {/* <div className="d-flex justify-content-center mt-5 gap-5 ">
            <Button className="btn">
              Yes
            </Button>
            <Button className="btn">
              No
            </Button>
          </div> */}
            </ModalBody>
          </Modal>
          {loading ? (
        <div className="loader-overlay">
          <div className="spinner"></div>
        </div>
      ) : (
        <Modal
        isOpen={consentModal}
        toggle={toggleConsentModal}
        size="lg"
        className="modal-dialog-centered"
        backdrop = "false"
      >
        <ModalBody>
          <div>
            <iframe
              src={`https://docs.google.com/viewer?url=${encodeURIComponent(
                cisDoc
              )}&embedded=true`}
              width="100%"
              height="650px"
            ></iframe>
          </div>
        </ModalBody>
        <div className="hdfc-pfd">
          <div className="d-flex align-items-start gap-3 fs-3">
            <input
              type="checkbox"
              required
              className="mt-2"
              style={{
                width: "20px",
                height: "20px",
              }}
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <span>
              Please click the checkbox if you have read the consent doc and
              want to proceed further.
            </span>
            {showError && (
              <div className="text-danger mt-2 fs-5">
                Please check the box to proceed.
              </div>
            )}
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <button
              className="Cancel-btn btn-danger mt-5"
              onClick={handleConsentBack}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary mt-5"
              onClick={handleConsent}
              disabled={!isChecked}
            >
              Proceed
            </button>
          </div>
        </div>
      </Modal>
      )}
   
        
        </div>
      </section>
      {sharePaymentModal && (
        <SharePaymentQuotationModal
          sharePaymentModal={sharePaymentModal}
          toggleSharePaymentModal={toggleSharePaymentModal}
          quotationPage={false}
        />
      )}
    </Layout>
  )
}

export default PolicyOverview
