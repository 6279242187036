import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { PostData } from "../../api/apiHelper";
import { sendErrorMessage, sendSuccessMessage } from "../Services/PosService";
import Layout from "../common/Layout";

const ResetPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm({ mode: "onBlur" });
  const navigate = useNavigate();
  const loaction = useLocation();

  const userEmail = loaction?.state?.email;

  const password = watch("password");

  const handleReset = (data) => {
    let Data = {
      email: userEmail,
      password: data.passwordRepeat,
      otp: data.otp,
    };
    PostData("auth/reset", Data).then((response) => {
      if (response.status === true) {
        navigate("/");
        sendSuccessMessage(response);
      } else {
        sendErrorMessage(response);
      }
    });
  };
  return (
    <>
      <Layout>
        <section className="mylogin mt-5 py-5">
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-lg-6 col-12 d-lg-block d-none">
                <img
                  src="/assetss/images/LoginPageImg.png"
                  className="w-100"
                  alt=""
                />
              </div>

              <div className="col-lg-5 ms-auto">
                <form onSubmit={handleSubmit(handleReset)}>
                  <div className="card shadow-lg border-0">
                    <div className="card-body p-5">
                      <div className="form-floating mb-5 pb-4">
                        <input
                          type="number"
                          className="form-control"
                          id="upass3"
                          placeholder=" New Password"
                          {...register("otp", {
                            required: "Otp is required",
                          })}
                        />
                        <label htmlFor="upass3">
                          Enter Otp
                          <span className="text-danger">*</span>
                        </label>

                        <p className="f-error">{errors?.otp?.message}</p>
                      </div>

                      <div className="form-floating mb-5 pb-4">
                        <input
                          type="password"
                          className="form-control"
                          id="upass"
                          placeholder=" New Password"
                          {...register("password", {
                            required: "Password is required",
                            minLength: {
                              value: 6,
                              message: "Password must be at least 6 characters",
                            },
                          })}
                        />
                        <label htmlFor="upass">
                          New Password
                          <span className="text-danger">*</span>
                        </label>

                        <p className="f-error">{errors?.password?.message}</p>
                      </div>

                      <div className="form-floating mb-5 pb-4">
                        <input
                          type="password"
                          className="form-control"
                          id="upass2"
                          placeholder="Confirm Password"
                          {...register("passwordRepeat", {
                            required: `Password is required`,
                            validate: (value) =>
                              value === password || `The password do not match`,
                            minLength: {
                              value: 6,
                              message: `Password length must be at least 6 characters long`,
                            },
                          })}
                        />
                        <label htmlFor="upass2">
                          Confirm Password
                          <span className="text-danger">*</span>
                        </label>

                        <p className="f-error">
                          {errors?.passwordRepeat?.message}
                        </p>
                      </div>

                      <button
                        type="submit"
                        className="btn btn-primary w-100 py-3 fs-1"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              {/*----- Login form container End -----*/}
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default ResetPassword;
