import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
  const phoneNumber = +911244363759;
  const navigate = useNavigate();
  const setVehicleType = (vehicleType, vehicleModel) => {
    const updatedVType = {
      vehicleModal: vehicleModel,
      vehicleType: vehicleType,
    };
    navigate("/motor-process", {
      state: updatedVType,
    });
    // setVtype(updatedVType);
  };

  const [openSections, setOpenSections] = useState({});

  // const toggleListItem = (item) => {
  //   if (openItems.includes(item)) {
  //     setOpenItems(openItems.filter((i) => i !== item));
  //   } else {
  //     setOpenItems([...openItems, item]);
  //   }
  // };

  const toggleAccordion = (section) => {
    // Create a copy of the openSections object
    const newOpenSections = { ...openSections };

    if (newOpenSections[section]) {
      // If the section is open, close it
      delete newOpenSections[section];
    } else {
      // If the section is closed, open it
      newOpenSections[section] = true;
    }

    // Update the state with the new openSections object
    setOpenSections(newOpenSections);
  };
  return (
    <footer>
      <div className="footblack">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="footlocation">
                <div className="footlogo">
                  <Link to={"/"}>
                    <img
                      src={"/assetss/images/footlogo.png"}
                      className="img-fluid"
                      alt="img"
                    />
                  </Link>
                </div>
                <div className="ftlocation">
                  <a
                    href="https://www.google.com/maps/place/CORPORATE+PLAZA/@28.4744142,77.0151383,17z/data=!3m1!4b1!4m6!3m5!1s0x390d19d11788b655:0x574c7ab29235c4dc!8m2!3d28.4744095!4d77.0177132!16s%2Fg%2F11t762kv44?entry=ttu"
                    target="blank"
                    className="text-decoration-none text-white fs-5"
                  >
                    <img
                      src={"/assetss/images/locationicon.png"}
                      alt="img"
                      className="img-fluid"
                    />
                    <h2>Location</h2>
                    F-201, Tower A, Palam Corporate Plaza, Near Park View
                    Building, Palam Vihar, <br />
                    Gurgaon-122017
                  </a>
                </div>

                <div className="ftlocation">
                  <a
                    className="text-lowercase"
                    href="mailto:support@expertcover.com"
                  >
                    <img
                      src={"/assetss/images/emailaddressicon.png"}
                      alt="img"
                      className="img-fluid"
                    />
                    <h2 className="">Email Address</h2>
                    <p>info@expertcover.com</p>
                  </a>
                </div>

                <div className="ftlocation">
                  <a href={`tel:${phoneNumber}`}>
                    <img
                      src={"/assetss/images/phoneicon.png"}
                      alt="img"
                      className="img-fluid"
                    />
                    <h2>Phone</h2>
                    <span>
                      <a
                        href="tel:+917862002244"
                        className="me-2 text-decoration-none text-white fs-5"
                      >
                        +91 - 7862002244
                      </a>
                      <span className="text-decoration-none text-white fs-5">
                        /
                      </span>
                      <a
                        href="tel:01244363759"
                        className="ms-2 text-decoration-none text-white fs-5"
                      >
                        0124 - 4363759
                      </a>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="footlinksec">
                <div className="footlinkbox">
                  <h2
                    className={`course-accordion ${
                      openSections["lifeInsurance"] ? "active" : ""
                    }`}
                    onClick={() => toggleAccordion("lifeInsurance")}
                  >
                    Life Insurance
                  </h2>
                  <div
                    className={`course-panel ${
                      openSections["lifeInsurance"] ? "open" : ""
                    }`}
                  >
                    <ul>
                      <li>
                        <Link to="/life-insurance">Life Insurance</Link>
                      </li>
                      <li>
                        <Link to="/term-insurance">Term Insurance</Link>
                      </li>
                      <li>
                        <Link to="/term-return">Term Return of Premium</Link>
                      </li>
                      <li>
                        <Link to="/spouse-term-plan">Spouse Term Plan</Link>
                      </li>
                      {/* <li>
                        <a to="#">Term Insurance Calculator</a>
                      </li>
                      <li>
                        <a to="#">Term Insurance for Housewife</a>
                      </li>
                      <li>
                        <a to="#">1 Crore Term Insurance<</a>
                      </li> */}
                    </ul>
                  </div>
                </div>

                <div className="footlinkbox">
                  <h2
                    className={`course-accordion ${
                      openSections["healthInsurance"] ? "active" : ""
                    }`}
                    onClick={() => toggleAccordion("healthInsurance")}
                  >
                    Health Insurance
                  </h2>
                  <div
                    className={`course-panel ${
                      openSections["healthInsurance"] ? "open" : ""
                    }`}
                  >
                    <ul>
                      <li>
                        <Link to="/">Health Insurance</Link>
                      </li>
                      <li>
                        <Link to="/coronavirus">Coronavirus Insurance</Link>
                      </li>
                      <li>
                        <Link to="/family-health-insurance">
                          Family Health Insurance
                        </Link>
                      </li>
                      <li>
                        <Link to="/senior-citizen">
                          Senior Citizen Health Insurance
                        </Link>
                      </li>
                      {/* <li>
                        <a to="#">1 Cr Health Cover</a>
                      </li>
                      <li>
                        <a to="#">Corona Kavach Policy</a>
                      </li>
                      <li>
                        <a to="#">Corona Rakshak Policy</a>
                      </li>
                      <li>
                        <a to="#">Arogya Sanjeevani Policy</a>
                      </li> */}
                    </ul>
                  </div>
                </div>

                <div className="footlinkbox">
                  <h2
                    className={`course-accordion ${
                      openSections["investment"] ? "active" : ""
                    }`}
                    onClick={() => toggleAccordion("investment")}
                  >
                    Investment
                  </h2>
                  <div
                    className={`course-panel ${
                      openSections["investment"] ? "open" : ""
                    }`}
                  >
                    <ul>
                      <li>
                        <Link to="/investment-plans">Investment Plans</Link>
                      </li>
                      <li>
                        <Link to="/capital-guarantee-plan">
                          Capital Guarantee Plans
                        </Link>
                      </li>
                      <li>
                        <Link to="/investment-nri-plan">
                          Investment Plans for NRIs
                        </Link>
                      </li>
                      <li>
                        <Link to="/child-plan">Child Plans</Link>
                      </li>
                      {/* <li>
                        <a to="#">Pension Plans</a>
                      </li>
                      <li>
                        <a to="#">Guaranteed Return Plans</a>
                      </li>
                      <li>
                        <a to="#">Tax Saving Investments</a>
                      </li>
                      <li>
                        <a to="#">Market Linked Investment Plans (ULIP)</a>
                      </li>
                      <li>
                        <a to="#">SIP (Systematic Investment Plan)</a>
                      </li>
                      <li>
                        <a to="#">Endowment Policy</a>
                      </li>
                      <li>
                        <a to="#">LIC</a>
                      </li>
                      <li>
                        <a to="#">Income Tax Calculator</a>
                      </li>
                      <li>
                        <a to="#">SIP Calculator</a>
                      </li>
                      <li>
                        <a to="#">NPS calculator</a>
                      </li>
                      <li>
                        <a to="#">FD Calculator</a>
                      </li> */}
                    </ul>
                  </div>
                </div>

                <div className="footlinkbox">
                  <h2
                    className={`course-accordion ${
                      openSections["generalInsurance"] ? "active" : ""
                    }`}
                    onClick={() => toggleAccordion("generalInsurance")}
                  >
                    General Insurance
                  </h2>
                  <div
                    className={`course-panel ${
                      openSections["generalInsurance"] ? "open" : ""
                    }`}
                  >
                    <ul>
                      <li>
                        <a
                          href=""
                          onClick={() => {
                            setVehicleType("4w", "Pvt Car");
                          }}
                        >
                          Car Insurance
                        </a>
                      </li>
                      <li>
                        <a
                          href=""
                          onClick={() => {
                            setVehicleType("2w", "MotorBike");
                          }}
                        >
                          Bike Insurance
                        </a>
                      </li>
                      <li>
                        <a
                          href=""
                          onClick={() => {
                            setVehicleType("gcv", "Goods Carrying");
                            // toggleModal();
                          }}
                        >
                          GCV Insurance
                        </a>
                      </li>
                      <li>
                        <a
                          href=""
                          onClick={() => {
                            setVehicleType("pcv", "Passenger Carrying");
                          }}
                        >
                          PCV Insurance
                        </a>
                      </li>
                      {/* <li>
                        <a to="#">Third Party Bike Insurance</a>
                      </li>
                      <li>
                        <a to="#">Travel Insurance</a>
                      </li>
                      <li>
                        <a to="#">Commercial Vehicle Insurance</a>
                      </li> */}
                    </ul>
                  </div>
                </div>

                <div className="footlinkbox">
                  <h2
                    className={`course-accordion ${
                      openSections["groupHealthInsurance"] ? "active" : ""
                    }`}
                    onClick={() => toggleAccordion("groupHealthInsurance")}
                  >
                    Other Insurance
                  </h2>
                  <div
                    className={`course-panel ${
                      openSections["groupHealthInsurance"] ? "open" : ""
                    }`}
                  >
                    <ul>
                      <li>
                        <Link to="/group-health-insurance">
                          Group Health Insurance
                        </Link>
                      </li>
                      <li>
                        <Link to="/cyber">Cyber Insurance</Link>
                      </li>
                      <li>
                        <Link to="/home-insurance">Home Insurance</Link>
                      </li>
                      <li>
                        <Link to="/business-insurance">Business Insurance</Link>
                      </li>
                      {/* <li>
                        <a to="#">Workers Compensation</a>
                      </li>
                      <li>
                        <a to="#">Cancer Insurance</a>
                      </li>
                      <li>
                        <a to="#">D&O Insurance</a>
                      </li> 
                    */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <ul className="footmainlinks">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about-us">About Us</Link>
                </li>
                <li>
                  <Link to="/blog">Blog</Link>
                </li>
                <li>
                  <Link to="/">Sitemap</Link>
                </li>
                <li>
                  <Link to="/">Careers</Link>
                </li>
                <li>
                  <Link to="/">ISNP</Link>
                </li>
                <li>
                  <Link to="/">Insurance Companies</Link>
                </li>
                <li>
                  <Link to="/">Articles</Link>
                </li>
                <li>
                  <Link to="/contact-us">Contact Us</Link>
                </li>
                <li>
                  <Link to="/">Know your advisor </Link>
                </li>
                <li>
                  <Link to="/">Newsroom</Link>
                </li>
                <li>
                  <Link to="/">Security</Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="footsocialsec">
                <ul className="footsocialcard">
                  <li>
                    <img src={"/assetss/images/cardicon.png"} alt="img" />
                  </li>
                </ul>

                <ul className="footsocialicon">
                  <li>
                    <Link to="/">
                      <img src={"/assetss/images/facebookicon.png"} alt="img" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <img src={"/assetss/images/twittericon.png"} alt="img" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <img src={"/assetss/images/linkedicon.png"} alt="img" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footwh d-flex justify-content-between align-items-center mx-4">
        <p className="copyright">
          &copy; 2023. Expert Cover Insurance Company. All Rights Reserved.
        </p>
        <a
          className="fit"
          target="_blank"
          href="https://www.flaskitsolutions.com/"
        >
          Designed & Developed by{" "}
          <span className="fs-2 fw-bold" style={{ color: "#0684c5" }}>
            FiT
          </span>
        </a>
      </div>

      <div id="elevator_item">
        <a id="elevator" onclick="return false;" title="Back To Top"></a>
      </div>
    </footer>
  );
};

export default Footer;
